import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CommonHttpServiceService } from '../../Services/common-http-service.service';
import { DatePipe } from '@angular/common';
import { CommonProvider } from 'src/app/shared/Providers/Shared/CommonProvider';
import { DAILY_DROPDOWN_DETAILS, DAILY_REPORT } from '../../Models/general-headings.model';
import { RegionModel } from 'src/app/model/contract/channel';
import * as moment from 'moment';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { type } from 'os';
@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent implements OnInit {
  public form: FormGroup;
  submittedCampaign: boolean;
  minDate: Date;
  projectSettings: any[] = DAILY_REPORT;
  dropdown: any[] = DAILY_DROPDOWN_DETAILS;
  maxDate: Date;
  startdate: string;
  enddate: string;
  reports: any[];
  weekOrMonth: any[];
  showWeek: boolean = false;
  showDate: boolean;
  flag: any;
  dateRange : any;
  regions: RegionModel[];
  selectedRegion: any;
  data: any;
  isLink: any;
  constructor(public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    private httpClient: CommonHttpServiceService,
    public ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    public hubAdminDataProvider: HubAdminDataProvider,) {
      this.maxDate = new Date();
    }

  ngOnInit() {

  }

  message: any;

  errorMessage : any;

  searchByFilter() {
    this.errorMessage = "";
    if(typeof this.dateRange != "undefined") {
      let date = moment(this.dateRange).format("YYYY-MM-DD");
      if(typeof this.selectedRegion =="undefined") this.selectedRegion = '';
      if(typeof this.selectedState =="undefined") this.selectedState = '';
      if(typeof this.selectedReport == "undefined") {
        this.dropdown.find(i => i.id == 1).errorStatus = true;
        return;
      }
      this.dropdown.find(i => i.id == 1).errorStatus = false;
      debugger
      this.httpClient.getDailyReports(
      this.selectedRegion, date, this.selectedState, this.selectedReport)
      .subscribe((res: any) => {
      if(res.success) {
        if(res.data.daily_reports.length > 0) {
          this.data = res.data.daily_reports;
        }
        else{
          this.data = [];
          this.message = 'Your Report request has been received. We will get back to with Report Download Link.';
          this.toastr.warning(`${this.message}`,
            'Dashboard', {
            timeOut: 10000,
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
        }
      }
    },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            // this.alertService.error(error.data.message);
            this.toastr.error(`Internal Server Error`,
              'Dashboard', {
              timeOut: 10000,
              closeButton: true,
              positionClass: "toast-bottom-right",
            });
          }
        }
      );
    }
    else{
      this.errorMessage = "Please select the date"
    }
  }

  resetDropdown: boolean;

  resetFilter() {
    this.resetDropdown = true;
  }

  StatesfilterByRegion : any[];
  states : any[];
  selectedReport: any;
  selectedState : any;
  selectedValueCheck(event, filter): void {
    switch (filter) {
        case 1:
              this.selectedReport = event.find(i => i.id).id;
              break;
        case 2:
          this.selectedRegion = event.find(i => i.id).id;
          this.StatesfilterByRegion= this.states.filter(s => s.region_id == this.selectedRegion);
          this.dropdown.find(d => d.id == 3).data = this.StatesfilterByRegion;
          break;
        case 3:
          this.selectedState = event.find(i => i.id).id;
          break;
    }
    console.log("general:", event, filter);
  }

  ngDoCheck() {
    this.regions = this.commonProvider.regionList;
    this.reports = this.commonProvider.reportLists;
    this.states = this.commonProvider.states;
    if(typeof this.reports !="undefined") {
      if(this.reports.length > 0) {
        this.reports = this.reports.filter(r => r.is_frequency == 1);
      }
    }

    this.dropdown.find(d => d.id == 1).data = this.reports;
    this.dropdown.find(d => d.id == 2).data = this.regions;
  }

}
