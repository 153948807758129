import { Component, OnInit } from '@angular/core';
import { CommonProvider } from 'src/app/shared/Providers/Shared/CommonProvider';
import { StateModel } from 'src/app/model/contract/menu-program';
import { ChannelModel, FrequencyModel, RegionModel } from 'src/app/model/contract/channel';
import { DROPDOWN_DETAILS, M1_PERFOMANCE, M2_PERFOMANCE, M3_PERFOMANCE, TMM_PERFOMANCE, CSM_PERFOMANCE, COLOR_TITLE, M2_VISIBILITY,M3_VISIBILITY } from '../Models/general-headings.model';
import { TileModel, WhitleTile } from '../Models/tile_data.model';
import { CommonHttpServiceService } from '../Services/common-http-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
declare var $:any;
@Component({
  selector: 'app-visiblity-cooler',
  templateUrl: './visiblity-cooler.component.html',
  styleUrls: ['./visiblity-cooler.component.scss']
})
export class VisiblityCoolerComponent implements OnInit {
  dateRange: any = [];
  data:any;
  colors: any[] = COLOR_TITLE;
  channerWiseClr:string = '#ECECEC';
  //Table Headers
  M1Perfomance: any[] = M1_PERFOMANCE;
  M2Perfomance: any[] = M2_VISIBILITY;
  M3Perfomance: any[] = M3_VISIBILITY;
  TMMPerfomance: any[] = TMM_PERFOMANCE;
  CSMPerfomance: any[] = CSM_PERFOMANCE;
  //Table header @Input
  m1Settings = this.M1Perfomance;
  m2Settings = this.M2Perfomance;
  m3Settings = this.M3Perfomance;
  TMMSettings = this.TMMPerfomance;
  CSMSettings = this.CSMPerfomance;

  //Data Variables;
  states: StateModel[];
  channels: ChannelModel[];
  frequency: FrequencyModel[];
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  StatesfilterByRegion: StateModel[];
  dropdown: any[] = DROPDOWN_DETAILS;

  // Date Variable;
  start_date: any;
  end_date: any;

  // Dropdown Selected Values;
  selectedRegion: number[]=[];
  selectedState: number[]=[];
  selectedChannel: number[]=[];
  selectedM1: number[]=[];
  selectedM2: number[]=[];
  selectedM3:number[]=[];
  startDate: any;
  endDate: any;
  min: number;
  max: number;
  smonth:any='0';
  sdate:any='0';
  options:Array<any>=[];
  channelWise:any;
  regionTargets:any = [];
  totalPointsData:any= [];
  resetDropdown: boolean;
  region = "";
  state = "";
  channel = "";
  m1 = "";
  m2 = "";
  m3 = "";

  constructor(
    public commonProvider: CommonProvider,
    private httpClient: CommonHttpServiceService,
    public ngxLoader: NgxUiLoaderService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private hubAdmin: HubAdminDataProvider
    ) { }

  ngOnInit() {
    this.getVisiblityReport(0, 0, 0, 0, 0, 0, 0, 0,0,0);
    this.min = 2019,
    this.max = new Date().getFullYear();
    let options = '<option>Select Year</option>'
    for (var i = this.min; i<=this.max; i++){
      options += '<option value="'+i+'">'+i+'</option>'
      this.options.push(i);

  }
  this.options.reverse();
  }
  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;

    this.dropdown.find(d => d.id == 1).data = this.regions;
    // this.dropdown.find(d => d.id == 2).data = this.states;
    this.dropdown.find(d => d.id == 3).data = this.channels;
    this.dropdown.find(d => d.id == 4).data = this.m1Users;
    this.dropdown.find(d => d.id == 5).data = this.m2Users;
    this.dropdown.find(d => d.id == 6).data = this.m3Users;
  }

  selectedValueCheck(event, filter): void {
    // console.log("general:", event, filter);
    console.log(event);

    switch (filter) {
      case 1:
        this.StatesfilterByRegion=[];
        if(event !=0)
                {

          event.forEach(element => {
            let data = this.states.filter(s => s.region_id == element.id);
            this.StatesfilterByRegion.push(...data);
          });
        }
      this.region = this.getList(event,'selectedRegion');
      console.log(this.region);
        this.dropdown.find(d => d.id == 2).data = this.StatesfilterByRegion;
        break;
      case 2:
        debugger
       this.state =  this.getList(event,'selectedState');

        break;
      case 3:
        this.channel = this.getList(event,'selectedChannel');

        break;
      case 4:
      this.m1 =  this.getList(event,'selectedM1');

        break;
      case 5:
        // this.selectedM2 = event;
        this.m2 = this.getList(event,'selectedM2');
        break;
      case 6:
        // this.selectedM3 = event;
        this.m3 =this.getList(event,'selectedM3');
        break;
    }
    // this.searchByFilter();
  }



  getList(event,mainData){
    this[mainData] = event;

    var z = [];
    var g;
    if(event.length != 0){
      event.forEach(element => {
       z.push(element.id)

    });
    g = z.join();
    this[mainData] = z;
    console.log(this[mainData]);

    }
    else{
      g = ''
    }

    return g;

  }

  Cumulative_pointsData = [];

  getVisiblityReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id,month,year) {
    this.ngxLoader.start();
    this.remove();
    this.httpClient.getVisibilityCooler(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id,month,year)
    .subscribe(
      (result: any) => {
        this.ngxLoader.stop();
        this.data = result.data;
        this.remove();
        this.data.region_targets.forEach((element,i) => {
          let data: TileModel = new TileModel;
          data.color = this.colors[i];
          data.name = element.name;
          data.value = element.value;
          this.regionTargets.push(data);
        });

        this.data.total_points.forEach((element,i) => {
            let data: TileModel = new TileModel;
            data.color = this.colors[i];
            data.name = element.name;
            data.value = element.value;
            this.totalPointsData.push(data);
        });

        if(this.data.audit_data.length != 0){
          this.Cumulative_pointsData = [];
          this.data.audit_data.forEach(element => {
            let data: WhitleTile = new WhitleTile;
            data.data = element.data;
            data.name = element.name;
            (data.name.includes("%")) ? data.type = '%': '';
            (data.name.includes("INR")) ? data.data = '₹ ' + data.data: '';
            this.Cumulative_pointsData.push(data);
          });
       }
        console.log(this.data.channelwise_partcipation);
      },
      error => {
        // unauthorized access
        if (error.status == 401 || error.status == 403) {
        } else {
          // this.alertService.error(error.data.message);
          this.toastr.error(`Internal Server Error`,
              'Dashboard', {
                timeOut: 10000,
                closeButton: true,
                positionClass: "toast-bottom-right",
            });
        }
      }
    )
  }

  remove() {
    this.totalPointsData = [];
    this.regionTargets = [];
    this.Cumulative_pointsData = [];
  }

  errorMsg: any = '';
  errorMsg1: any = '';

  searchByFilter(){
    this.errorMsg = '';
    this.errorMsg1 = '';
    this.resetDropdown = false;
    if(this.dateRange.length == 0){
      this.startDate= 0;
      this.endDate = 0;
    }
    else{
      this.startDate = this.datepipe.transform(this.dateRange[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(this.dateRange[1], 'yyyy-MM-dd');
    }


    if (this.selectedRegion === undefined || this.selectedRegion.length==0 ) {
      this.region = '';
    }

    if (this.selectedState === undefined || this.selectedState.length==0 ) {
      this.state = '';
    }

    if (this.selectedChannel === undefined || this.selectedChannel.length == 0) {
      this.channel = '';
    }

    if (this.selectedM1 === undefined || this.selectedM1.length == 0) {
      this.m1 = '';
    }

    if (this.selectedM2 === undefined || this.selectedM2.length == 0) {
      this.m2 = '';
    }

    if (this.selectedM3 === undefined || this.selectedM3.length == 0) {
      this.m3 = '';
    }


    if(this.smonth =="0" && this.sdate =="0" ){
      

      this.errorMsg = "Please select year";
      this.errorMsg1 = "Please select month";

      // if(parseInt(this.smonth) !=0) {
      //   if(parseInt(this.sdate) !=0) {
      //       this.getGeneralReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3,this.whitelist_date1,this.smonth,this.sdate);
      //   }
      //   else{
      //     this.errorMsg = "Please select year";
      //     this.errorMsg1 = "Please select month";
      //   }
      // }
    }
    else{
      if(typeof this.smonth !="undefined") {
        if(parseInt(this.smonth) !=0) {
          if(parseInt(this.sdate) !=0) {
            this.getVisiblityReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3,this.smonth,this.sdate);
          }
          else{
            this.errorMsg = "Please select year";
          }
        }
      }
  
      if(typeof this.sdate !="undefined") {
        if(parseInt(this.sdate) !=0) {
          if(parseInt(this.smonth) !=0) {
            this.getVisiblityReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3,this.smonth,this.sdate);
          }
          else{
            this.errorMsg1 = "Please select month";
          }
        }
      }
    }
    // if(typeof this.smonth !="undefined") {
    //   if(parseInt(this.smonth) !=0) {
    //     if(parseInt(this.sdate) !=0) {
    //       this.getVisiblityReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3,this.smonth,this.sdate);
    //     }
    //     else{
    //       this.errorMsg = "Please select year";
    //     }
    //   }
    // }

    // if(typeof this.sdate !="undefined") {
    //   if(parseInt(this.sdate) !=0) {
    //     if(parseInt(this.smonth) !=0) {
    //       this.getVisiblityReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3,this.smonth,this.sdate);
    //     }
    //     else{
    //       this.errorMsg1 = "Please select month";
    //     }
    //   }
    // }


    //this.getVisiblityReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3,this.smonth,this.sdate);
  }

  public convetToPDF() {
    this.ngxLoader.startBackground();
    var data = document.getElementById('Program-Overview');
    $(data).prepend("<p id='gr' style='font-size:40px;margin:40px 0px 60px 0px;'>Visiblity Cooler</p> ");
    html2canvas(data).then(canvas => {
    var imgWidth = 208;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    const contentDataURL = canvas.toDataURL('image/png')
    let pdf =  new jsPDF.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    var position = 0;
    heightLeft -= pageHeight;
    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
    }
      pdf.save('content_report.pdf');
      this.ngxLoader.stopBackground();
    }).then(()=>{
      $('#gr').remove();
    });
 }

 resetFilter() {
  this.resetDropdown = true;
  this.dateRange = [];
  location.reload();
  console.log("Reset");
 }
}


