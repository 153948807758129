import { Component, OnInit } from '@angular/core';
import { StaffService } from 'src/app/model/staff.service';
import { UserDataService } from 'src/app/model/user-data.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { ToastrService } from 'ngx-toastr';
import { ChartModel, ChannelModel } from 'src/app/model/contract/channel';
import { trigger, transition, query, stagger, animate, style } from '@angular/animations';
import { StateModel } from 'src/app/model/contract/menu-program';
import { RegionDataService } from 'src/app/model/region-data.service';

@Component({
  selector: 'app-engagement',
  templateUrl: './engagement.component.html',
  styleUrls: ['./engagement.component.scss'],
  animations: [
    trigger('simple', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('5s', style({ opacity: 0 }))
          ])
        ],
          { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('2s', style({ opacity: 1 }))
          ])
        ], { optional: true })
      ])
    ])
  ]

})
export class EngagementComponent implements OnInit {
  oneAtATime: boolean = true;
  userListModel: any;
  router: any;
  searchParams: any;
  totalCount: any;
  errorMessage: any;

  private _form: FormGroup;
  region: number;
  averagepointsearned: any;
  regionList: any;
  topperformingregions: any;
  selectedChannel: number;
  state: number;
  selectedRole: number;
  tse_user_id: number;
  m2_user_id: number;
  m3_user_id: number;
  avgengagementopenrate: any;
  avgtimespentperengagement: any;

  constructor(private staffService: StaffService,
    private users: UserDataService,
    private _formBuilder: FormBuilder,
    public settingDataService: SettingDataService,
    public datepipe: DatePipe, public formBuilder: FormBuilder,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private toastr: ToastrService,
    public usersService: RegionDataService) {

    this._form = this._formBuilder.group({
      date: ['',],
      region: ['',],
      state: ['',],
      channel: ['',],
      role: ['',],
      tse_user_id : ['', ],
      m2_user_id : ['', ],
      m3_user_id : ['',]
    });


  }
  RoleList: any[];

  ngOnInit() {
    this.RoleList = [{ id: 14, name: 'TSE' }, { id: 15, name: 'M2' }, { id: 4, name: 'TSE' }]
    this.getUserList(1);
    this.getRegions();
    this.getStateList();
    this.getChannels();
    this.getUsers();
    this.region = 0;
    this.getEngagementReport(0, 0, 0, 0, 0, 0, 0, 0)
    this.region = 0;
    this.selectedChannel = 0;
    this.state = 0;
    this.selectedRole = 0;
    this.tse_user_id = 0;
    this.m2_user_id = 0;
    this.m3_user_id = 0;
  }

  startDate: any = 0;
  endDate: any = 0;
  onSearch(date: any, region: any, state: any, channel: any, tse_user_id, m2_user_id, m3_user_id) {
    if (date != "") {
      this.startDate = this.datepipe.transform(date[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(date[1], 'yyyy-MM-dd');
    }
    this.getEngagementReport(this.startDate, this.endDate, region, state, channel, tse_user_id, m2_user_id, m3_user_id);
  }

  getUserList(currentPage) {
    this.userListModel = null;
    this.users.getAllHubAdminUsers(1)
      .subscribe(
        (response: any) => {
          this.userListModel = response.data.users;
          this.totalCount = response.data.count.count;
        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
            this.staffService.unauthorizedAccess(error);
          } else {
            this.errorMessage = error.data.message;
          }
        }
      )
  }

  totalengagementvscompleted: any[];
  circleWiseData: Array<string> = [];
  public circleLabels: Array<string> = [];
  circleWiseData1: Array<string> = [];
  public circleLabels1: Array<string> = [];
  enagementPoints: number = 0;
  topperformingengagement: any[];
  topPerformingCSM: any[];
  public TSEDailybarChartLabels: any[] = [];
  public TSEDialybarChartData: any[] = [];
  public engagementCom: any;
  public enagementMonth: any[];
  public enangagementRegionPar: any[];
  public enangagementStatePar: any[];
  public TSEDailybarChartLabels2: any[] = [];
  public TSEDialybarChartData2: any[] = [];
  public TSEDailybarChartLabels3: any[] = [];
  public TSEDialybarChartData3: any[] = [];
  public TSEDailybarChartLabels4: any[] = [];
  public TSEDialybarChartData4: any[] = [];
  public TSEDailybarChartLabels5: any[] = [];
  public TSEDialybarChartData5: any[] = [];
  public TSEDailybarChartLabels6: any[] = [];
  public TSEDialybarChartData6: any[] = [];
  public TSEDailybarChartLabels7: any[] = [];
  public TSEDialybarChartData7: any[] = [];

  getEngagementReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id) {
    this.ngxLoader.start();
    this.setAllData();
    this.users.getEngagmentReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id)
      .subscribe(
        (result: any) => {
          this.totalengagementvscompleted = result.data.totalengagementvscompleted.data;
          this.averagepointsearned = result.data.averagepointsearned;
          this.engagementCom = result.data.totalengagementvscompleted[0];
          console.log(this.engagementCom);
          if (this.totalengagementvscompleted.length !== 0) {
            this.circleLabels = ['Total Completed', 'Total Engagement'];
            this.circleWiseData = (result.data.totalengagementvscompleted.data);
          }
          if (Object.getOwnPropertyNames(result.data.totalengagementvscompletedmonth).length !== 0) {
            let data = result.data.totalengagementvscompletedmonth;
            this.enagementMonth = data;
            this.TSEDailybarChartLabels = Object.values(result.data.totalengagementvscompletedmonth).map(e => e['month']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.totalengagementvscompletedmonth).map(e => e['total_task']);
              chart.label = 'Total Task';
            });

            data.forEach(element => {
              chart1.data = Object.values(result.data.totalengagementvscompletedmonth).map(e => e['completed_tasks']);
              chart1.label = 'Completed Task';
            });

            this.TSEDialybarChartData.push(chart);
            this.TSEDialybarChartData.push(chart1);
          }

          if (Object.getOwnPropertyNames(result.data.statewiselanddparticipation).length !== 0) {
            let data = result.data.statewiselanddparticipation;
            this.enangagementStatePar = data;
            this.TSEDailybarChartLabels5 = Object.values(result.data.statewiselanddparticipation).map(e => e['state_name']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.statewiselanddparticipation).map(e => e['total_count']);
              chart.label = 'Partcipation Count';
            });

            this.TSEDialybarChartData5.push(chart);
          }

          if (Object.getOwnPropertyNames(result.data.regionwiselanddparticipation).length !== 0) {
            let data = result.data.regionwiselanddparticipation;
            this.enangagementRegionPar = data;
            this.TSEDailybarChartLabels4 = Object.values(result.data.regionwiselanddparticipation).map(e => e['region_name']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.regionwiselanddparticipation).map(e => e['participation_count']);
              chart.label = 'Partcipation Count';
            });
            this.TSEDialybarChartData4.push(chart);
          }

          if (Object.getOwnPropertyNames(result.data.avgengagementopenrate).length !== 0) {
            let data = result.data.avgengagementopenrate;
            this.avgengagementopenrate = data;
            this.TSEDailybarChartLabels6 = Object.values(result.data.avgengagementopenrate).map(e => e['task_title']);
            let chart: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.avgengagementopenrate).map(e => e['avg_open_rate']);
              chart.label = 'Average Open Rate';
            });

            this.TSEDialybarChartData6.push(chart);
          }


          if (Object.getOwnPropertyNames(result.data.avgtimespentperengagement).length !== 0) {
            let data = result.data.avgtimespentperengagement;
            this.avgtimespentperengagement = data;
            this.TSEDailybarChartLabels7 = Object.values(result.data.avgtimespentperengagement).map(e => e['task_title']);
            let chart: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.avgtimespentperengagement).map(e => e['avg_spent_time_mins']);
              chart.label = 'Average Open Rate';
            });

            this.TSEDialybarChartData7.push(chart);
          }



          this.enagementPoints = result.data.totalpointsgiveninengagement;
          this.topPerformingCSM = result.data.topperformingcsm;
          this.topperformingengagement = result.data.topperformingengagement;
          this.topperformingregions = result.data.topperformingregions;
          this.ngxLoader.stop();
        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            this.alertService.error(error.data.message);
          }
        }
      )
  }

  setAllData() {
    this.TSEDialybarChartData7 = [];
    this.TSEDailybarChartLabels7 = [];
    this.TSEDialybarChartData6 = [];
    this.TSEDailybarChartLabels6 = [];
    this.TSEDialybarChartData5 = [];
    this.TSEDailybarChartLabels5 = [];
    this.TSEDialybarChartData4 = [];
    this.TSEDailybarChartLabels4 = [];
    this.TSEDialybarChartData3 = [];
    this.TSEDailybarChartLabels3 = [];
    this.TSEDialybarChartData2 = [];
    this.TSEDialybarChartData2 = [];
    this.TSEDialybarChartData = [];
    this.TSEDailybarChartLabels = [];
    this.circleLabels = [];
    this.circleWiseData = [];
  }





  public pieChartColors = [
    {
      backgroundColor: ['#003f5c', '#58508d'],
    },
  ];

  public pieChartColors1 = [
    {
      backgroundColor: ['#bc5090', '#ff6361'],
    },
  ];

  public barChartColors4: any[] = [
    {
      backgroundColor: '#e60000',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#ff6666',
      pointBorderColor: '#fff'
    },
  ]

  public barChartColors5: any[] = [
    {
      backgroundColor: '#663300',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#ff6666',
      pointBorderColor: '#fff'
    },
  ]

  public barChartColors6: any[] = [
    {
      backgroundColor: '#99b2b2',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#ff6666',
      pointBorderColor: '#fff'
    },
  ]

  getRegions(): void {
    this.settingDataService.getAllRegion()
      .subscribe(
        (result: any) => {
          this.regionList = result.data.regions;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  onReset() {
    this.ngOnInit();
  }

  tseusers : any[];
  m2users : any[];
  m3users : any[];

  getUsers(): void {
    this.users.getUsers()
      .subscribe(
        (result: any) => {
          this.tseusers = result.data.tseusers;
          this.m2users = result.data.m2users;
          this.m3users = result.data.m3users;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  onChangeRegion(item) {
    this.selectedStates = this.states.filter(s => s.region_id == item);
  }

  states: StateModel[];
  selectedStates: StateModel[];
  getStateList(): void {
    this.usersService.getAllStates().subscribe(
      (data) => {
        this.states = data;
      }
    )
  }

  channelList: ChannelModel[];

  getChannels(): void {
    this.settingDataService.getAllChannel()
      .subscribe(
        (result: any) => {
          this.channelList = result.data.channels;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  selectChannel() {

  }

}
