import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StaffService } from 'src/app/model/staff.service';
import { UserDataService } from 'src/app/model/user-data.service';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { ToastrService } from 'ngx-toastr';
import { ChartModel, RegionModel, ChannelModel } from 'src/app/model/contract/channel';
import { RegionDataService } from 'src/app/model/region-data.service';
import { StateModel } from 'src/app/model/contract/menu-program';

@Component({
  selector: 'app-reward-reports',
  templateUrl: './reward-reports.component.html',
  styleUrls: ['./reward-reports.component.scss']
})
export class RewardReportsComponent implements OnInit {
  oneAtATime: boolean = true;
  userListModel: any;
  router: any;
  searchParams: any;
  totalCount: any;
  errorMessage: any;

  private _form: FormGroup;
  region: number;
  averagepointsearned: any;
  regionList: any;
  topperformingregions: any;
  enagementMonth1: any;
  selectedChannel: number;
  state: number;
  selectedRole: number;
  tse_user_id: number;
  m2_user_id: number;
  m3_user_id: number;
  tseusers: any;
  m2users: any;
  m3users: any;
  pointsredempercentage: any;
  totalpointsburned: number;

  constructor(private staffService: StaffService,
    private users: UserDataService,
    private _formBuilder: FormBuilder,
    public settingDataService: SettingDataService,
    public datepipe: DatePipe, public formBuilder: FormBuilder,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private toastr: ToastrService,
    public usersService: RegionDataService) {

    this._form = this._formBuilder.group({
      date: ['',],
      region: ['',],
      state: ['',],
      channel: ['',],
      role: ['',],
      tse_user_id: ['',],
      m2_user_id: ['',],
      m3_user_id: ['',]
    });


  }

  ngOnInit() {
    this.getRegions();
    this.getStateList();
    this.getChannels();
    this.getUsers();
    this.region = 0;
    this.getEngagementReport(0, 0, 0, 0, 0, 0, 0, 0)
    this.region = 0;
    this.selectedChannel = 0;
    this.state = 0;
    this.selectedRole = 0;
    this.tse_user_id = 0;
    this.m2_user_id = 0;
    this.m3_user_id = 0;
  }

  startDate: any = 0;
  endDate: any = 0;
  onSearch(date: any, region: any, state: any, channel: any, tse_user_id, m2_user_id, m3_user_id) {
    if (date != "") {
      this.startDate = this.datepipe.transform(date[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(date[1], 'yyyy-MM-dd');
    }
    this.getEngagementReport(this.startDate, this.endDate, region, state, channel, tse_user_id, m2_user_id, m3_user_id);
  }


  totalengagementvscompleted: any[];
  circleWiseData: Array<string> = [];
  public circleLabels: Array<string> = [];
  circleWiseData1: Array<string> = [];
  public circleLabels1: Array<string> = [];
  enagementPoints: number = 0;
  topperformingengagement: any[];
  topPerformingCSM: any[];
  public TSEDailybarChartLabels: any[] = [];
  public TSEDialybarChartData: any[] = [];
  public engagementCom: any;
  public enagementMonth: any[];
  public TSEDailybarChartLabels2: any[] = [];
  public TSEDialybarChartData2: any[] = [];
  public TSEDailybarChartLabels3: any[] = [];
  public TSEDialybarChartData3: any[] = [];
  public TSEDailybarChartLabels4: any[] = [];
  public TSEDialybarChartData4: any[] = [];

  getEngagementReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id) {
    this.ngxLoader.start();
    this.setAllData();
    this.users.getRewardDashboardReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id)
      .subscribe(
        (result: any) => {
          this.averagepointsearned = result.data.averagepointsburned;
          this.totalpointsburned = result.data.totalpointsburned;
          console.log(this.engagementCom);

          if (Object.getOwnPropertyNames(result.data.monthwisepoints).length !== 0) {
            let data = result.data.monthwisepoints;
            this.enagementMonth = data;
            this.TSEDailybarChartLabels = Object.values(result.data.monthwisepoints).map(e => e['month']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.monthwisepoints).map(e => e['points_issued']);
              chart.label = 'Points Issued';
            });

            data.forEach(element => {
              chart1.data = Object.values(result.data.monthwisepoints).map(e => e['points_redeemed']);
              chart1.label = 'Points Redeemed';
            });

            this.TSEDialybarChartData.push(chart);
            this.TSEDialybarChartData.push(chart1);
          }


          if (Object.getOwnPropertyNames(result.data.regionwisepoints).length !== 0) {
            let data = result.data.regionwisepoints;
            this.enagementMonth1 = data;
            this.TSEDailybarChartLabels3 = Object.values(result.data.regionwisepoints).map(e => e['region_name']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.regionwisepoints).map(e => e['points_earned']);
              chart.label = 'Points Earned';
            });

            data.forEach(element => {
              chart1.data = Object.values(result.data.regionwisepoints).map(e => e['points_redeemed']);
              chart1.label = 'Points Redeemed';
            });

            this.TSEDialybarChartData3.push(chart);
            this.TSEDialybarChartData3.push(chart1);
          }

          if (Object.getOwnPropertyNames(result.data.pointsredempercentage).length !== 0) {
            let data = result.data.pointsredempercentage;
            this.pointsredempercentage = data;
            this.TSEDailybarChartLabels4 = Object.values(result.data.pointsredempercentage).map(e => e['region_name']);
            let chart: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.pointsredempercentage).map(e => e['avg_points_percentage']);
              chart.label = 'Average Points';
            });

            this.TSEDialybarChartData4.push(chart);

          }


          

          this.topPerformingCSM = result.data.top5rewards;
          this.topperformingengagement = result.data.bottom4rewards;
          this.ngxLoader.stop();
        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            this.alertService.error(error.data.message);
          }
        }
      )
  }

  setAllData() {
    this.TSEDialybarChartData4 = [];
    this.TSEDailybarChartLabels4 = [];
    this.TSEDialybarChartData3 = [];
    this.TSEDailybarChartLabels3 = [];
    this.TSEDialybarChartData2 = [];
    this.TSEDialybarChartData2 = [];
    this.TSEDialybarChartData = [];
    this.TSEDailybarChartLabels = [];
    this.circleLabels = [];
    this.circleWiseData = [];
  }


  public pieChartColors = [
    {
      backgroundColor: ['#003f5c', '#58508d'],
    },
  ];

  public pieChartColors1 = [
    {
      backgroundColor: ['#bc5090', '#ff6361'],
    },
  ];

  public barChartColors4: any[] = [
    {
      backgroundColor: '#e60000',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#ff6666',
      pointBorderColor: '#fff'
    },
  ]

  public barChartColors5: any[] = [
    {
      backgroundColor: '#003f5c',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#58508d',
      pointBorderColor: '#fff'
    },
  ]

  public barChartColors6: any[] = [
    {
      backgroundColor: '#99b2b2',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#ff6666',
      pointBorderColor: '#fff'
    },
  ]

  getRegions(): void {
    this.settingDataService.getAllRegion()
      .subscribe(
        (result: any) => {
          this.regionList = result.data.regions;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  onChangeRegion(item) {
    this.selectedStates = this.states.filter(s => s.region_id == item);
  }

  states: StateModel[];
  selectedStates: StateModel[];
  getStateList(): void {
    this.usersService.getAllStates().subscribe(
      (data) => {
        this.states = data;
      }
    )
  }

  onReset() {
    this.ngOnInit();
  }

  selectChannel() {

  }

  channelList: ChannelModel[];

  getChannels(): void {
    this.settingDataService.getAllChannel()
      .subscribe(
        (result: any) => {
          this.channelList = result.data.channels;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getUsers(): void {
    this.users.getUsers()
      .subscribe(
        (result: any) => {
          this.tseusers = result.data.tseusers;
          this.m2users = result.data.m2users;
          this.m3users = result.data.m3users;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

}
