import { Component, OnInit } from '@angular/core';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { UserDataService } from 'src/app/model/user-data.service';
import { ToastrService } from 'ngx-toastr';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { DatePipe } from '@angular/common';
import { ChartModel, RegionModel, ChannelModel } from 'src/app/model/contract/channel';
import { StateModel } from 'src/app/model/contract/menu-program';
import { RegionDataService } from 'src/app/model/region-data.service';

@Component({
  selector: 'app-visiblity',
  templateUrl: './visiblity.component.html',
  styleUrls: ['./visiblity.component.scss']
})
export class VisiblityComponent implements OnInit {
  oneAtATime: boolean = true;
  private _form: FormGroup;
  region: number;
  visiblityCount1: any;
  successfailurepercent: any;
  tseusers: any;
  m2users: any;
  m3users: any;
  selectedChannel: number;
  state: number;
  selectedRole: number;
  tse_user_id: number;
  m2_user_id: number;
  m3_user_id: number;

  constructor(private hubAdminDataProvider: HubAdminDataProvider,
    public routerDataProvider: RouteDataProvider,
    public formBuilder: FormBuilder,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private users: UserDataService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    public settingDataService: SettingDataService,
    public datepipe: DatePipe,
    public usersService: RegionDataService) {

    this._form = this._formBuilder.group({
      date: ['',],
      region: ['',],
      state: ['',],
      channel: ['',],
      role: ['',],
      tse_user_id: ['',],
      m2_user_id: ['',],
      m3_user_id: ['',]
    });

  }

  ngOnInit() {
    this.getRegions();
    this.getStateList();
    this.getChannels();
    this.getUsers();
    this.region = 0;
    this.getReport(0, 0, 0, 0, 0, 0, 0, 0)
    this.region = 0;
    this.selectedChannel = 0;
    this.state = 0;
    this.selectedRole = 0;
    this.tse_user_id = 0;
    this.m2_user_id = 0;
    this.m3_user_id = 0;
  }

  circleWiseData: Array<string> = [];
  public circleLabels: Array<string> = [];

  public TSEDailybarChartLabels: any[] = [
  ];

  public TSEDialybarChartData: any[] = [
  ];

  public TSEDailybarChartLabels1: any[] = [
  ];

  public TSEDialybarChartData1: any[] = [
  ];

  public TSEDailybarChartLabels2: any[] = [
  ];

  public TSEDialybarChartData2: any[] = [
  ];

  public TSEDailybarChartLabels3: any[] = [
  ];

  public TSEDialybarChartData3: any[] = [
  ];

  visiblityCount: any[];
  wowCount: any[];
  regionWise: any[];
  stateWise: any[];

  startDate: any = 0;
  endDate: any = 0;
  onSearch(date: any, region: any, state: any, channel: any, tse_user_id, m2_user_id, m3_user_id) {
    if (date != "") {
      this.startDate = this.datepipe.transform(date[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(date[1], 'yyyy-MM-dd');
    }
    this.getReport(this.startDate, this.endDate, region, state, channel, tse_user_id, m2_user_id, m3_user_id);
  }

  getReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id) {
    this.ngxLoader.start();
    this.setAllBlank();
    this.users.getVisiblityReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id)
      .subscribe(
        (result: any) => {
          if (Object.getOwnPropertyNames(result.data.posmwiseapprovalrejection).length !== 0) {
            let data = result.data.posmwiseapprovalrejection;
            this.visiblityCount = data;
            this.TSEDailybarChartLabels = Object.values(result.data.posmwiseapprovalrejection).map(e => e['posm_type']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            let chart2: ChartModel = new ChartModel;
            data.forEach(element => {
              chart2.data = Object.values(result.data.posmwiseapprovalrejection).map(e => e['posm_pending']);
              chart2.label = 'POSM Pending';
            });

            data.forEach(element => {
              chart.data = Object.values(result.data.posmwiseapprovalrejection).map(e => e['posm_approved']);
              chart.label = 'POSM Approved';
            });

            data.forEach(element => {
              chart1.data = Object.values(result.data.posmwiseapprovalrejection).map(e => e['posm_rejected']);
              chart1.label = 'POSM Rejected';
            });

            this.TSEDialybarChartData.push(chart2);
            this.TSEDialybarChartData.push(chart);
            this.TSEDialybarChartData.push(chart1);
          }

          if (Object.getOwnPropertyNames(result.data.channeliseposmcount).length !== 0) {
            let data = result.data.channeliseposmcount;
            this.visiblityCount1 = data;
            this.TSEDailybarChartLabels2 = Object.values(result.data.channeliseposmcount).map(e => e['channel_desc']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            let chart2: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.channeliseposmcount).map(e => e['posm_pending_count']);
              chart.label = 'Pending';
            });

            data.forEach(element => {
              chart1.data = Object.values(result.data.channeliseposmcount).map(e => e['posm_approved_count']);
              chart1.label = 'Approved';
            });

            data.forEach(element => {
              chart2.data = Object.values(result.data.channeliseposmcount).map(e => e['posm_rejected_count']);
              chart2.label = 'Rejected';
            });

            this.TSEDialybarChartData2.push(chart);
            this.TSEDialybarChartData2.push(chart1);
            this.TSEDialybarChartData2.push(chart2);
          }

          if (Object.getOwnPropertyNames(result.data.wowposmcounts).length !== 0) {
            let data = result.data.wowposmcounts;
            this.wowCount = data;
            this.TSEDailybarChartLabels1 = Object.values(result.data.wowposmcounts).map(e => e['date_range']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.wowposmcounts).map(e => e['datacount']);
              chart.label = 'Total Count';
            });

            this.TSEDialybarChartData1.push(chart);

          }

          if (Object.getOwnPropertyNames(result.data.statewiseparticipant).length !== 0) {
            let data = result.data.statewiseparticipant;
            this.stateWise = data;
            this.TSEDailybarChartLabels3 = Object.values(result.data.statewiseparticipant).map(e => e['state_name']);
            let chart: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.statewiseparticipant).map(e => e['datacount']);
              chart.label = 'Total Count';
            });

            this.TSEDialybarChartData3.push(chart);

          }

          if (Object.getOwnPropertyNames(result.data.regionwiseposmcount).length !== 0) {
            this.regionWise = result.data.regionwiseposmcount;
            this.circleWiseData = Object.values(result.data.regionwiseposmcount.data);
            this.circleLabels = Object.values(result.data.regionwiseposmcount.label);
          }

          this.successfailurepercent = result.data.successfailurepercent;

          this.ngxLoader.stop();
        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            this.alertService.error(error.data.message);
          }
        }
      )
  }

  regionList: RegionModel[];

  getRegions(): void {
    this.settingDataService.getAllRegion()
      .subscribe(
        (result: any) => {
          this.regionList = result.data.regions;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  setAllBlank() {
    this.circleLabels = [];
    this.circleWiseData = [];
    this.TSEDailybarChartLabels1 = [];
    this.TSEDialybarChartData1 = [];
    this.TSEDialybarChartData = [];
    this.TSEDailybarChartLabels = [];
    this.TSEDialybarChartData2 = [];
    this.TSEDailybarChartLabels2 = [];
    this.TSEDialybarChartData3 = [];
    this.TSEDailybarChartLabels3 = [];
  }

  public barChartColors: any[] = [
    {
      backgroundColor: '#0040ff',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#b3c6ff',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#b2004c',
      pointBorderColor: '#fff'
    }
  ]

  public barChartColors2: any[] = [
    {
      backgroundColor: '#663300',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#99b2b2',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#e60000',
      pointBorderColor: '#fff'
    },
  ]

  public barChartColors1: any[] = [
    {
      backgroundColor: '#b2004c',
      pointBorderColor: '#fff'
    },
  ]

  public pieChartColors = [
    {
      backgroundColor: ['#F28A68', '#DB7569'],
    },
  ];



  onChangeRegion(item) {
    this.selectedStates = this.states.filter(s => s.region_id == item);
  }

  states: StateModel[];
  selectedStates: StateModel[];
  getStateList(): void {
    this.usersService.getAllStates().subscribe(
      (data) => {
        this.states = data;
      }
    )
  }

  onReset() {
    this.ngOnInit();
  }

  selectChannel() {

  }

  channelList: ChannelModel[];

  getChannels(): void {
    this.settingDataService.getAllChannel()
      .subscribe(
        (result: any) => {
          this.channelList = result.data.channels;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getUsers(): void {
    this.users.getUsers()
      .subscribe(
        (result: any) => {
          this.tseusers = result.data.tseusers;
          this.m2users = result.data.m2users;
          this.m3users = result.data.m3users;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

}
