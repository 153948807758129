import { Component, OnInit, Input } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @Input() pieChartLabels: Label[];
  @Input() public pieChartData: number[];
  public pieChartColors = [
    {
      backgroundColor: ['#ff471a', '#e60000', '#b30000', '#800000', '#4d0000'],
    },
  ];

  public pieChartOptions: any = {
    responsive: true,
    legend: {
      position: 'top',
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var dataset = data.datasets[(tooltipItem.datasetIndex)];
          var total = dataset.data.reduce(function(previousValue, currentValue) {
            return parseInt(previousValue) + parseInt(currentValue);
          });
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = Math.floor(((currentValue/total) * 100)+0.5);         
          return percentage + "%";
        }
      }
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          // const label = ctx.chart.data.labels[ctx.dataIndex];
          // return label;
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          let percentage = (value * 100 / sum).toFixed(2) + "%";
          console.log(percentage);
          return percentage;
        },
      },
    },
  };

 


  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;


  constructor() { }

  ngOnInit() {
  }

}
