import { Component, OnInit, Input } from '@angular/core';
import { StaffService } from 'src/app/model/staff.service';
import { UserDataService } from 'src/app/model/user-data.service';

@Component({
  selector: 'app-leaderboard-chart',
  templateUrl: './leaderboard-chart.component.html',
  styleUrls: ['./leaderboard-chart.component.scss']
})
export class LeaderboardChartComponent implements OnInit {
  @Input() userListModel: any;
  router: any;
  searchParams: any;
  totalCount: any;
  errorMessage: any;
  currentPage: number;
  pageSize: number;
  @Input() type: number;

  constructor(private staffService: StaffService, private users: UserDataService) { }

  ngOnInit() {

  }

}
