import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StaffService } from 'src/app/model/staff.service';
import { UserDataService } from 'src/app/model/user-data.service';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { ToastrService } from 'ngx-toastr';
import { ChartModel, ChannelModel } from 'src/app/model/contract/channel';
import { RegionDataService } from 'src/app/model/region-data.service';
import { StateModel } from 'src/app/model/contract/menu-program';

@Component({
  selector: 'app-crown-report',
  templateUrl: './crown-report.component.html',
  styleUrls: ['./crown-report.component.scss']
})
export class CrownReportComponent implements OnInit {
  userListModel: any;
  router: any;
  searchParams: any;
  totalCount: any;
  errorMessage: any;

  private _form: FormGroup;
  region: number;
  averagepointsearned: any;
  regionList: any;
  topperformingregions: any;
  enangagementRegionPar1: any;
  selectedChannel: number;
  state: number;
  selectedRole: number;
  tse_user_id: number;
  m2_user_id: number;
  m3_user_id: number;
  tseusers: any;
  m2users: any;
  m3users: any;
  totalpointstile: any;
  top10tseachivements: any;
  tagetassginedpercent: any;

  constructor(private staffService: StaffService,
    private users: UserDataService,
    private _formBuilder: FormBuilder,
    public settingDataService: SettingDataService,
    public datepipe: DatePipe, public formBuilder: FormBuilder,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private toastr: ToastrService,
    public usersService: RegionDataService) {

    this._form = this._formBuilder.group({
      date: ['',],
      region: ['',],
      state: ['',],
      channel: ['',],
      role: ['',],
      tse_user_id: ['',],
      m2_user_id: ['',],
      m3_user_id: ['',]
    });


  }

  ngOnInit() {
    this.getRegions();
    this.getStateList();
    this.getChannels();
    this.getUsers();
    this.region = 0;
    this.getEngagementReport(0, 0, 0, 0, 0, 0, 0, 0)
    this.region = 0;
    this.selectedChannel = 0;
    this.state = 0;
    this.selectedRole = 0;
    this.tse_user_id = 0;
    this.m2_user_id = 0;
    this.m3_user_id = 0;
  }

  startDate: any = 0;
  endDate: any = 0;
  onSearch(date: any, region: any, state: any, channel: any, tse_user_id, m2_user_id, m3_user_id) {
    if (date != "") {
      this.startDate = this.datepipe.transform(date[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(date[1], 'yyyy-MM-dd');
    }
    this.getEngagementReport(this.startDate, this.endDate, region, state, channel, tse_user_id, m2_user_id, m3_user_id);
  }

  getUserList(currentPage) {
    this.userListModel = null;
    this.users.getAllHubAdminUsers(1)
      .subscribe(
        (response: any) => {
          this.userListModel = response.data.users;
          this.totalCount = response.data.count.count;
        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
            this.staffService.unauthorizedAccess(error);
          } else {
            this.errorMessage = error.data.message;
          }
        }
      )
  }

  totalengagementvscompleted: any[];
  circleWiseData: Array<string> = [];
  public circleLabels: Array<string> = [];
  circleWiseData1: Array<string> = [];
  public circleLabels1: Array<string> = [];
  enagementPoints: number = 0;
  topperformingengagement: any[];
  topPerformingCSM: any[];
  public TSEDailybarChartLabels: any[] = [];
  public TSEDialybarChartData: any[] = [];
  public engagementCom: any;
  public enagementMonth: any[];
  public enangagementRegionPar: any[];
  public enangagementStatePar: any[];
  public enangagementStatePar1: any[];
  public TSEDailybarChartLabels2: any[] = [];
  public TSEDialybarChartData2: any[] = [];
  public TSEDailybarChartLabels3: any[] = [];
  public TSEDialybarChartData3: any[] = [];
  public TSEDailybarChartLabels4: any[] = [];
  public TSEDialybarChartData4: any[] = [];
  public TSEDailybarChartLabels5: any[] = [];
  public TSEDialybarChartData5: any[] = [];
  public TSEDailybarChartLabels6: any[] = [];
  public TSEDialybarChartData6: any[] = [];
  public TSEDailybarChartLabels7: any[] = [];
  public TSEDialybarChartData7: any[] = [];

  getEngagementReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id) {
    this.ngxLoader.start();
    this.setAllData();
    this.users.getCrownReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id)
      .subscribe(
        (result: any) => {
          this.totalpointstile = result.data.totalpointstile;
          if (Object.getOwnPropertyNames(result.data.monthlytargets).length !== 0) {
            let data = result.data.monthlytargets;
            this.enagementMonth = data;
            this.TSEDailybarChartLabels = Object.values(result.data.monthlytargets).map(e => e['month_year']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.monthlytargets).map(e => e['sum']);
              chart.label = 'Total';
            });

            this.TSEDialybarChartData.push(chart);
          }

          if (Object.getOwnPropertyNames(result.data.monthlyachivements).length !== 0) {
            let data = result.data.monthlyachivements;
            this.enangagementStatePar = data;
            this.TSEDailybarChartLabels5 = Object.values(result.data.monthlyachivements).map(e => e['month_year']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.monthlyachivements).map(e => e['sum']);
              chart.label = 'Total';
            });

            this.TSEDialybarChartData5.push(chart);
          }

          if (Object.getOwnPropertyNames(result.data.targetvscompleted).length !== 0) {
            let data = result.data.targetvscompleted;
            this.enangagementRegionPar = data;
            this.TSEDailybarChartLabels4 = Object.values(result.data.targetvscompleted).map(e => e['target_month_year']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.targetvscompleted).map(e => e['target_quantity']);
              chart.label = 'Target Quantity';
            });
            data.forEach(element => {
              chart1.data = Object.values(result.data.targetvscompleted).map(e => e['achivement_quantity']);
              chart1.label = 'Achivement Quantity';
            });
            this.TSEDialybarChartData4.push(chart);
            this.TSEDialybarChartData4.push(chart1);
          }

          if (Object.getOwnPropertyNames(result.data.waiteronboard).length !== 0) {
            let data = result.data.waiteronboard;
            this.enangagementRegionPar1 = data;
            this.TSEDailybarChartLabels6 = Object.values(result.data.waiteronboard).map(e => e['month']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.waiteronboard).map(e => e['onboarding_count']);
              chart.label = 'Onboarding Count';
            });

            this.TSEDialybarChartData6.push(chart);
          }

          if (Object.getOwnPropertyNames(result.data.tagetassginedpercent).length !== 0) {
            let data = result.data.tagetassginedpercent;
            this.tagetassginedpercent = data;
            this.TSEDailybarChartLabels7 = Object.values(result.data.tagetassginedpercent).map(e => e['region_name']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            let chart2: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.tagetassginedpercent).map(e => e['total_qty']);
              chart.label = 'Total Quantity';
            });

            data.forEach(element => {
              chart1.data = Object.values(result.data.tagetassginedpercent).map(e => e['assigned_qty']);
              chart1.label = 'Assigned Quantity';
            });

            this.TSEDialybarChartData7.push(chart);
            this.TSEDialybarChartData7.push(chart1);

            
          }

          this.top10tseachivements = result.data.top10tseachivements;

          this.ngxLoader.stop();
        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            this.alertService.error(error.data.message);
          }
        }
      )
  }

  setAllData() {
    this.TSEDialybarChartData7 = [];
    this.TSEDailybarChartLabels7 = [];
    this.TSEDialybarChartData5 = [];
    this.TSEDailybarChartLabels5 = [];
    this.TSEDialybarChartData6 = [];
    this.TSEDailybarChartLabels6 = [];
    this.TSEDialybarChartData4 = [];
    this.TSEDailybarChartLabels4 = [];
    this.TSEDialybarChartData3 = [];
    this.TSEDailybarChartLabels3 = [];
    this.TSEDialybarChartData2 = [];
    this.TSEDialybarChartData2 = [];
    this.TSEDialybarChartData = [];
    this.TSEDailybarChartLabels = [];
    this.circleLabels = [];
    this.circleWiseData = [];
  }


  public pieChartColors = [
    {
      backgroundColor: ['#003f5c', '#58508d'],
    },
  ];

  public pieChartColors1 = [
    {
      backgroundColor: ['#bc5090', '#ff6361'],
    },
  ];

  public barChartColors4: any[] = [
    {
      backgroundColor: '#e60000',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#ff6666',
      pointBorderColor: '#fff'
    },
  ]

  public barChartColors5: any[] = [
    {
      backgroundColor: '#663300',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#ff6666',
      pointBorderColor: '#fff'
    },
  ]

  public barChartColors6: any[] = [
    {
      backgroundColor: '#99b2b2',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#ff6666',
      pointBorderColor: '#fff'
    },
  ]

  getRegions(): void {
    this.settingDataService.getAllRegion()
      .subscribe(
        (result: any) => {
          this.regionList = result.data.regions;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  onChangeRegion(item) {
    this.selectedStates = this.states.filter(s => s.region_id == item);
  }

  states: StateModel[];
  selectedStates: StateModel[];
  getStateList(): void {
    this.usersService.getAllStates().subscribe(
      (data) => {
        this.states = data;
      }
    )
  }

  onReset() {
    this.ngOnInit();
  }

  selectChannel() {

  }

  channelList: ChannelModel[];

  getChannels(): void {
    this.settingDataService.getAllChannel()
      .subscribe(
        (result: any) => {
          this.channelList = result.data.channels;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getUsers(): void {
    this.users.getUsers()
      .subscribe(
        (result: any) => {
          this.tseusers = result.data.tseusers;
          this.m2users = result.data.m2users;
          this.m3users = result.data.m3users;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

}
