import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CommonModule } from "@angular/common";
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ModalModule, AlertComponent } from 'ngx-bootstrap';
import { TooltipModule } from 'ngx-bootstrap';
// Reactive Form
import { ReactiveFormsModule } from "@angular/forms";
// Routing Module
import { routing } from './app.routing';
// Layouts
import { P404Component } from './page/404.component';
// Shared
import { AuthGuard } from './model/auth.guard';
import { SharedModule } from './shared/shared.module';
import { environment } from './../environments/environment';
// Model & Services
import { GlobalService } from './model/global.service';
import { StaffService } from './model/staff.service';
import { UserDataService } from './model/user-data.service';
import { SettingDataService } from './model/setting-data.service';
import { GeoHierachyDataService } from "./model/geo-hierachy-data.service";
import { AdminModule } from './admin/admin.module';
import { RegionDataService } from './model/region-data.service';
import { UserRoleService } from './model/user-role.service';
import { CampaignDataService } from './model/campaign-data.service';
import { CacheInterceptor } from './model/Http/cache.interceptor';
import { HubAdminDataProvider } from './shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { CommonProvider } from './shared/Providers/Shared/CommonProvider';
import { RouteDataProvider } from './shared/Providers/RouterProvider/RouteDataProvider.provider';
import { StaffDataService } from './model/staff-data.service';
import { DashboardModule } from './report-dashboard/dashboard.module';
import { ThemeService } from 'ng2-charts';
import { MysteryDashboardModule } from './mystery-dashboard/dashboard.module';
import { NesaDashboardModule } from './nesa-admin/dashboard.module';

export function tokenGetter() {
  return localStorage.getItem(environment.tokenName);
}

@NgModule({
  declarations: [
    AppComponent,
    P404Component,
    AlertComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    AdminModule,

    MysteryDashboardModule,
    NesaDashboardModule,
    DashboardModule,
    routing,
    HttpClientModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [environment.apiHost]
      }
    })
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    ToastrService,
    AuthGuard,
    StaffService,
    GlobalService,
    UserDataService,
    SettingDataService,
    GeoHierachyDataService,
    RegionDataService,
    UserRoleService,
    CampaignDataService,
    HubAdminDataProvider,
    StaffDataService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CacheInterceptor, multi: true
    // },
    CommonProvider,
    RouteDataProvider,
    ThemeService
  ],
  bootstrap: [AppComponent],

})
export class AppModule {
}
