import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BackendLayoutComponent } from './layout/backend-layout.component';
import { AuthGuard } from '../model/auth.guard';
import { GeneralComponent } from './general/general.component';
import { KingserviceComponent } from './kingservice/kingservice.component';
import { WeeklyChallengesComponent } from './weekly-challenges/weekly-challenges.component';
import { CrownCollectionComponent } from './crown-collection/crown-collection.component';
import { ContentandLearningComponent } from './contentand-learning/contentand-learning.component';
import { VisiblityBrandComponent } from './visiblity-brand/visiblity-brand.component';
import { VisiblityCoolerComponent } from './visiblity-cooler/visiblity-cooler.component';
import { MysteryshopperComponent } from './mysteryshopper/mysteryshopper.component';
import { EntryComponent } from './mysteryshopper/entry.component';
import { EntryMenuComponent } from './menu-program-overview/entry.component';
import { MenuProgramOverviewComponent } from './menu-program-overview/menu-program-overview.component';
import { RewardReportComponent } from '../admin/rewards/reward-report/reward-report.component';
import { DailyReportComponent } from './download-reports/daily-report/daily-report.component';
import { WeeklyReportComponent } from './download-reports/weekly-report/weekly-report.component';
import { MonthlyReportComponent } from './download-reports/monthly-report/monthly-report.component';
import { FirebaseComponent } from './download-reports/firebase/firebase.component';
import { MysteryshopperReportComponent } from './mysteryshopper-report/mysteryshopper-report.component';

const routes: Routes = [
  {
    path: 'report-admin',
    component: BackendLayoutComponent,
    data: {
      breadcrumb: 'Report Admin'
    },
    canActivate: [AuthGuard],
    children: [
      { path: 'general', component: GeneralComponent, data: { breadcrumb: 'General' } },
      { path: 'kingservice', component: KingserviceComponent, data: { breadcrumb: 'Kingservice' } },
      { path: 'weekly-challenges', component: WeeklyChallengesComponent, data: { breadcrumb: 'Weekly Challenges' } },
      { path: 'Crown-Collection', component: CrownCollectionComponent, data: { breadcrumb: 'Crown Collection' } },
      { path: 'content-learning', component: ContentandLearningComponent, data: { breadcrumb: 'Content & Learning' } },
      { path: 'Visiblity-Brand', component: VisiblityBrandComponent, data: { breadcrumb: 'Visiblity Brand' } },
      { path: 'Visiblity-Cooler', component: VisiblityCoolerComponent, data: { breadcrumb: 'Visiblity Cooler' } },
      { path: 'mystery-shopper', component: MysteryshopperReportComponent, data: { breadcrumb: 'mystery shopper' } },
      { path: 'mystery-shopper/:id', component: EntryComponent, data: { breadcrumb: 'Mystery Shopper' } },
      { path: 'menu-program-report', component: MenuProgramOverviewComponent, data: { breadcrumb: 'Menu Program' } },
      { path: 'menu-program-report/:id', component: EntryMenuComponent, data: { breadcrumb: 'Menu Program' } },
      { path: 'daily-reports', component: DailyReportComponent, data: { breadcrumb: 'Daily Reports' } },
      { path: 'weekly-reports', component: WeeklyReportComponent, data: { breadcrumb: 'Weekly Reports' } },
      { path: 'monthly-reports', component: MonthlyReportComponent, data: { breadcrumb: 'Monthly Reports' } },
      { path: 'firebase-reports', component: FirebaseComponent, data: { breadcrumb: 'Firebase Reports' } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {
}
