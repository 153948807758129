import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { SpinnerComponent } from './spinner/spinner.component';
import { environment } from '../../environments/environment';
import { PaginationModule, AccordionModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { TimepickerModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap';
import { StaffDataService } from "../model/staff-data.service";
import { CommonModule } from "@angular/common";
import { AuthGuard } from '../model/auth.guard';
import { RouteDataProvider } from './Providers/RouterProvider/RouteDataProvider.provider';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ProgressbarModule, ModalModule } from 'ngx-bootstrap';
import { DataTableModule } from 'angular2-datatable';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ChartsModule } from 'ng2-charts';
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const CUSTOM_DATETIME_FORMATS = environment.customDateTimeFormat;

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MomentModule,
    ChartsModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AngularMultiSelectModule,
    ProgressbarModule.forRoot(),
    DataTableModule,
    NgxUiLoaderModule,
    Ng2SearchPipeModule,
    RichTextEditorModule,
    ModalModule.forRoot(),
    AccordionModule.forRoot()
  ],
  declarations: [
    SpinnerComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    SpinnerComponent,
    PaginationModule,
    BsDatepickerModule,
    TimepickerModule,
    BsDropdownModule,
    NgMultiSelectDropDownModule,
    AngularMultiSelectModule,
    ProgressbarModule,
    DataTableModule,
    NgxUiLoaderModule,
    Ng2SearchPipeModule,
    RichTextEditorModule,
    ModalModule,
    ChartsModule,
    AccordionModule
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [RouteDataProvider, StaffDataService, AuthGuard]
    };
  }
}
