import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, retry } from 'rxjs/operators';
import { GlobalService } from 'src/app/model/global.service';
import { StaffService } from 'src/app/model/staff.service';
import { User } from 'src/app/model/contract/user';
import { GENERAL_REPORT } from '../Models/general.mock-data';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';
import { FIRE_ENAGEMENT_TIME_SPENT, FIRE_MAU_TIME_SPENT, FIRE_MODULE_TIME_SPENT, FIRE_SCHEME_TIME_SPENT, FIRE_TIME_SPENT, LIVEPOINT, QC_PANEL, REDEMPTION_MASTER, TARGET_AND_ACHIVEMENTS } from '../Models/general-headings.model';
import { listStyleType } from 'html2canvas/dist/types/css/property-descriptors/list-style-type';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  // Redmeption

  redemptionHeaders = REDEMPTION_MASTER;
  livePoint = LIVEPOINT;
  targetAchivements = TARGET_AND_ACHIVEMENTS;

  // Fire Base

  dauTimeSpent = FIRE_TIME_SPENT;
  enagementTimeSpent = FIRE_ENAGEMENT_TIME_SPENT;
  moduleTimeSpent = FIRE_MODULE_TIME_SPENT;
  schemtTimeSpent = FIRE_SCHEME_TIME_SPENT;
  mauTimeSpent= FIRE_MAU_TIME_SPENT;
  qcReport = QC_PANEL;



  constructor(private datePipe: DatePipe) { }

  pipe = new DatePipe('en-US');

  exportLivePointsExcel(excelData , selectedRegionName, selectedReportName) {

    const header = this.livePoint;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Live Points', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'B9D0E8'},
        bgColor: { argb: 'FF0000FF'}
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 11,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = { top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' } }
      }
    );

    worksheet.columns = [
      { header: 'SN',  key: 'sl_no' },
      { header: 'Osmosys Code',key: 'ro_code'},
      { header: 'Outlet Name',key: 'ro_name'},
      { header: 'State', key: 'state_name' },
      { header: 'Mobile Number',key: 'username'},
      { header: 'Earned Points', key: 'earned_points'},
      { header: 'Redeemed Points', key: 'redeemd_points'},
      { header: 'Unredeemed (LIVE) Points', key: 'live_points'},
      { header: 'User Status',key: 'user_status'},
    ]

    let totalEarnedPoints = 0;
    let totalRedemePoints = 0;
    let totalLivePoints = 0;

    excelData.forEach(d => {
      let row = worksheet.addRow(d);
      let qty = row.getCell(6);
      let qty1 = row.getCell(7);
      let qty2 = row.getCell(8);
      if(+qty.value) {
        totalEarnedPoints+= +qty.value;
       }
       if(+qty1.value) {
        totalRedemePoints+= +qty1.value;
       }
       if(+qty2.value) {
        totalLivePoints+= +qty2.value;
       }
        row.font = {
          name: 'Calibri',
          size: 11,
        },
        row.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
      }
    );
    let finalHeader = ["", "", "", "", "", "Total", "", "", ""];
    let lastRow= worksheet.addRow(finalHeader);
    lastRow.eachCell((cell, number) => {
      if(number == 4) {
        cell.value = "Total";
      }
      else if(number == 6) {
        cell.value = totalEarnedPoints;
      }
      else if(number == 7) {
        cell.value = totalRedemePoints;
      }
      else if(number == 8) {
        cell.value = totalLivePoints;
      }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'B9D0E8'},
        bgColor: { argb: 'FF0000FF'}
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 11,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = { top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' } }
      }
    );
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Live Points - Monthly_'+ selectedRegionName + selectedReportName + this.datePipe.transform(new Date(), 'medium')+ '.xlsx');
    })
  }

  exportRedmiptionExcel(excelData, selectedRegionName, selectedMonthName, selectedYearName) {

    const header = this.redemptionHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Redemptions', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'BEBEBE'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 10,
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );
    worksheet.columns = [
      { header: 'User ID',          key: 'user_id' },
      { header: 'Name',             key: 'mobile_no'},
      { header: 'Mobile.',          key: 'customer_name' },
      { header: 'Outlet Code',      key: 'outlet_code'},
      { header: 'Outlet Name',      key: 'outlet_name'},
      { header: 'Address',          key: 'address'},
      { header: 'State',            key: 'state_name'},
      { header: 'Created Date',     key: 'created_date'},
      { header: 'Status',           key: 'status'},
      { header: 'Order Ref No.',    key: 'order_refno'},
      { header: 'Product Name',     key: 'prod_name'},
      { header: 'Denomination',     key: 'denominations'},
      { header: 'Offer Code',       key: 'offer_code'},
      { header: 'Offer Code Pin',   key: 'offercode_pin'},
      { header: 'Response OrderID', key: 'response_orderid'},
      { header: 'Redeem Points',    key: 'redeem_points'},
    ]

    excelData.forEach(d => {
      let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
        row.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
      }
    );
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Redemption Master - Monthly_'+selectedRegionName+'_'+selectedMonthName+'_'+selectedYearName+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }

  exportTargetAndAchivementExcel(excelData, selectedRegionName, selectedMonthName, selectedYearName) {
    const header = this.targetAchivements;
    const header1 = ["","","","","","","","",
                      "",
                      "Achievement",
                     'Points'];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Outlet Wise Targets', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
      let headerRow1 = worksheet.addRow(header1);
      worksheet.getCell('J1').value = 'Target';
      worksheet.getCell('P1').value = 'Achievement';
      worksheet.getCell('U1').value = 'Points';
      worksheet.mergeCells('J1:P1');
      worksheet.mergeCells('Q1:W1');
      worksheet.mergeCells('X1:AD1');
      headerRow1.eachCell((cell, number) => {
      if(number <= 7 ) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '99CC00'},
          bgColor: { argb: 'FF0000FF'}
        }
      }
      else if(number >=8 && number <= 9){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B9D0E8'},
          bgColor: { argb: 'FF0000FF'},

        }
      }
      else if(number >=10 && number <= 16){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '9999FF'},
          bgColor: { argb: 'FF0000FF'}
        }
      }
      else if(number >=17 && number <= 23){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '90EE90'},
          bgColor: { argb: 'FF0000FF'}
        }
      }
      else if(number >=24 && number <= 31){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F5F5F5'},
          bgColor: { argb: 'FF0000FF'}
        }
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 9,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = { top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' } }
      }
    );
     //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      if(number <= 7 ) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '99CC00'},
          bgColor: { argb: 'FF0000FF'}
        }
      }
      else if(number >=8 && number <= 9){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B9D0E8'},
          bgColor: { argb: 'FF0000FF'},

        }
      }
      else if(number >=10 && number <= 16){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '9999FF'},
          bgColor: { argb: 'FF0000FF'}
        }
      }
      else if(number >=17 && number <= 23){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '90EE90'},
          bgColor: { argb: 'FF0000FF'}
        }
      }
      else if(number >=24 && number <= 31){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F5F5F5'},
          bgColor: { argb: 'FF0000FF'}
        }
      }
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 9,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.border = { top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' } }
      }
    );

    worksheet.columns = [
      { header: '',key: 'sl_no' },
      { header: '',key: 'ro_code' },
      { header: '',key: 'ro_name' },
      { header: '',key: 'tse_name' },
      { header: '',key: 'asm_name' },
      { header: '',key: 'state_name' },
      { header: '',key: 'channel_desc' },
      { header: '',key: 'csm_name' },
      { header: '',key: 'mobile_no' },
      { header: 'Haywards/Knockout',key: 'core_target_qty' },
      { header: 'Beck\'s Ice',key: 'becks_target_qty' },
      { header: 'Budweiser',key: 'premium_target_qty' },
      { header: 'Magnum',key: 'magnum_target_qty' },
      { header: 'Corona/Hoegaarden',key: 'ipb_target_qty' },
      { header: 'Veere/Macha',key: 'innovation_target_qty' },
      { header: 'Target',key: 'total_target_qty' },
      { header: 'Haywards/Knockout',key: 'core_achieved_qty' },
      { header: 'Beck\'s Ice',key: 'becks_achieved_qty' },
      { header: 'Budweiser',key: 'premium_achieved_qty' },
      { header: 'Magnum',key: 'magnum_achieved_qty' },
      { header: 'Corona/Hoegaarden',key: 'ipb_achieved_qty' },
      { header: 'Veere/Macha',key: 'innovation_achieved_qty' },
      { header: 'Achievement',key: 'total_achieved_qty' },
      { header: 'Haywards/Knockout',key: 'core_points' },
      { header: 'Beck\'s Ice',key: 'becks_points' },
      { header: 'Budweiser',key: 'premium_points' },
      { header: 'Magnum',key: 'magnum_points' },
      { header: 'Corona/Hoegaarden',key: 'ipb_points' },
      { header: 'Veere/Macha',key: 'innovation_points' },
      { header: 'Points',key: 'total_points' }

]

    excelData.forEach(d => {
      let row = worksheet.addRow(d);
        row.font = {
          name: 'Arial',
          size: 10,
        },
        row.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
      }
    );

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'T&A Master Monthly Report_'+selectedRegionName+'_'+selectedMonthName+'_'+selectedYearName+'.xlsx');
    })
  }

  /**********************************FIRE BASE REPORT*******************************************************/

  DAUTimeSpentExcel(excelData, selectedRegionName, selectedMonthName, selectedYearName) {
    const header = this.dauTimeSpent;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('DAU Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 10,
          color: { argb: 'FFFFFF'}
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );
    worksheet.columns = [
      { header: 'Sno',                key: 'sl_no' },
      { header: 'Date',               key: 'transdate'},
      { header: 'Osmosys Code.',      key: 'osmosyscode' },
      { header: 'Outlet Name',        key: 'outletname'},
      { header: 'CSM Number',         key: 'csmnumber'},
      { header: 'State',              key: 'outletstatename'},
      { header: 'Region',             key: 'outletregionname'},
      { header: 'Module Name',        key: 'modulename'},
      { header: 'Avg. Time Spent (In Seconds)', key: 'timespent'},
    ]

    if(excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
          row.font = {
            name: 'Arial',
            size: 10,
          },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
        }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'DAU Time Spent -'+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }


  EnagementTimeSpentExcel(excelData, selectedRegionName, selectedMonthName, selectedYearName) {
    const header = this.enagementTimeSpent;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Engagement Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 10,
          color: { argb: 'FFFFFF'}
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );
    worksheet.columns = [
      { header: 'Sno',                      key: 'sl_no' },
      { header: 'Osmosys Code.',            key: 'osmosyscode' },
      { header: 'Outlet Name',              key: 'outletname'},
      { header: 'CSM Number',               key: 'csmnumber'},
      { header: 'State',                    key: 'outletstatename'},
      { header: 'Region',                   key: 'outletregionname'},
      { header: 'Engagement Name',          key: 'engagementname'},
      { header: 'Time Spent (In Seconds)',  key: 'timespent'},
    ]

    if(excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
          row.font = {
            name: 'Arial',
            size: 10,
          },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
        }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Enagement Time Spent -'+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }

  MAUTimeSpentExcel(excelData, selectedRegionName, selectedMonthName, selectedYearName) {
    const header = this.mauTimeSpent;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('MAU Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 10,
          color: { argb: 'FFFFFF'}
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );
    worksheet.columns = [
      { header: 'Sno',                key: 'sl_no' },
      { header: 'Month/Year',         key: 'month_year'},
      { header: 'Osmosys Code.',      key: 'osmosyscode' },
      { header: 'Outlet Name',        key: 'outletname'},
      { header: 'CSM Number',         key: 'csmnumber'},
      { header: 'State',              key: 'outletstatename'},
      { header: 'Region',             key: 'outletregionname'},
      { header: 'Module Name',        key: 'modulename'},
      { header: 'Avg. Time Spent (In Seconds)', key: 'timespent'},
    ]

    if(excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
          row.font = {
            name: 'Arial',
            size: 10,
          },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
        }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'MAU Time Spent -'+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }

  ModuleTimeSpentExcel(excelData, selectedRegionName, selectedMonthName, selectedYearName) {
    const header = this.moduleTimeSpent;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Module Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 10,
          color: { argb: 'FFFFFF'}
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );
    worksheet.columns = [
      { header: 'Sno',                key: 'sl_no' },
      { header: 'Osmosys Code.',      key: 'osmosyscode' },
      { header: 'Outlet Name',        key: 'outletname'},
      { header: 'CSM Number',         key: 'csmnumber'},
      { header: 'State',              key: 'outletstatename'},
      { header: 'Region',             key: 'outletregionname'},
      { header: 'Module Name',        key: 'modulename'},
      { header: 'Time Spent (In Seconds)', key: 'timespent'},
    ]

    if(excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
          row.font = {
            name: 'Arial',
            size: 10,
          },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
        }
      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Module Time Spent -'+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }

  SchemeTimeSpentExcel(excelData, selectedRegionName, selectedMonthName, selectedYearName) {
    const header = this.schemtTimeSpent;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Scheme Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 10,
          color: { argb: 'FFFFFF'}
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );
    worksheet.columns = [
      { header: 'Sno',                key: 'sl_no' },
      { header: 'Osmosys Code.',      key: 'osmosyscode' },
      { header: 'Outlet Name',        key: 'outletname'},
      { header: 'CSM Number',         key: 'csmnumber'},
      { header: 'State',              key: 'outletstatename'},
      { header: 'Region',             key: 'outletregionname'},
      { header: 'Scheme Name',        key: 'engagementname'},
      { header: 'Date Clicked',       key: 'transaction_date'},
    ]

    if(excelData.length > 0) {
      excelData.forEach(d => {
        let row = worksheet.addRow(d);
          row.font = {
            name: 'Arial',
            size: 10,
          },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
        }
      );
    }

  worksheet.columns.forEach(function (column, i) {
    column.width = 26;
  });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Scheme -'+selectedRegionName+'_'+selectedMonthName+'_'+selectedYearName+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }


  /*****************************************************************************************/

  QcHeaderString: any;
  headersData : any = '';
  keyValueData : any = '';
  QcKeyValueString: any;

  QCExcel(excelData, selectedRegionName, selectedMonthName, selectedYearName) {
    this.headersData = '';
    if(excelData) {
      if(excelData[0].question_data.length > 0) {
          let i =0 ;
          let totalCount =  excelData[0].question_data.length;
          excelData[0].question_data.forEach(
            e => {
              if(i <= totalCount) {
                this.QcHeaderString =
                `Group ${i+1}` + ','
                +
                `Question ${i+1}` + ','
                +
                `Answer ${i+1}` + ','
                +
                ((e.question_type =="drop_down_yesno") ?
                (`Points ${i+1}` + ',' + `Available Points ${i+1}`): '')
                + ',';
                this.headersData += (typeof(this.QcHeaderString) != "undefined") ? this.QcHeaderString  : '';
              }
              i++;
            }
          );
      }
    };

    console.log(this.headersData);

    let header1 = [];
    let questionHeaders = [];
    let header2 =[];
    header1 = [
      'S.No',
      'Outlet Code',
      'Outlet Name',
      'Channel',
      'Group 1'
    ];

    questionHeaders = (this.headersData.split(","));

    header2 = [
      'Audit Config Date Time',
      'Audit Updated Date time',
      'Latitude',
      'Longtitude',
      'Total Points '
    ];

    const withQuestions = header1.concat(questionHeaders);
    const Headers  = withQuestions.concat(header2);
    const finalHeaders = Headers.filter( f => { return f != ""});
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Qc Report', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 10,
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );
    let j = 0 ;
    this.keyValueData = '';
    if(excelData[0].question_data.length > 0) {
      let totalCount =  excelData[0].question_data.length;
      excelData[0].question_data.forEach(
        element => {
          if( j <= totalCount) {
            this.QcKeyValueString =
            `{ "header": "Group ${j+1}", "key" : "group_name${j+1}"}` + '/,'
            +
            `{ "header": "Question ${j+1}", "key" : "question${j+1}"}` + '/,'
            +
            `{"header": "Answer ${j+1}" , "key" : "answer${j+1}"}` + '/,'
            +
            ((element.question_type =="drop_down_yesno") ?
            (`{"header": "Points ${j+1}", "key": "points${j+1}"}` + '/,' + `{"header":"Available Points","key": "available_points${j+1}"}`): '')
            + '/,';
            this.keyValueData += (typeof(this.QcKeyValueString) != "undefined") ? this.QcKeyValueString  : '';
          }
          j++;
        }
      )
    };
    //Question Data Array;
    let excelArray = [];
    excelData.forEach(element => {
      let i = 1;
      let person = {};
      element.question_data.forEach(element1 => {
        var Obj = {
          ['group_name'+ i] : element1.group_name,
          ['question'+ i]: element1.question,
          ['answer'+ i]: (element1.question_type =="image_upload")? [ 'image 1 : '+ element1.answer, element1.answer_images.map((e,i)=> `image ${i+2}: ` + e.image).join(" , ")].toString(): element1.answer,
        };
        if(element1.question_type == "drop_down_yesno") {
          var Obj1 = {
            ['points'+ i]: element1.points,
            ['available_points'+ i]: element1.available_points,
          }
          Obj = Object.assign(Obj, Obj1);
        }
        person = Object.assign(person, Obj);
        i++;
      });
      let finalObj = Object.assign({}, person, element);
      excelArray.push(finalObj);
    });

    let keyValueQuestionPair = this.keyValueData.split("/,");
    const finalKeyValuePair = keyValueQuestionPair.filter( f => { return f != ""});
    let columns1 = [];
    let columns2 = [];
    let column3 = [];
    let finalColumns = [];
    columns1 =
    [
      { header: 'S.No',                       key: 'sl_no' },
      { header: 'Outlet Code',                key: 'outlet_code'},
      { header: 'Outlet Name',                key: 'outlet_name' },
      { header: 'Channel',                    key: 'channel'},
    ];

    columns2 = columns1.concat(finalKeyValuePair);

    column3 = [
    { header: 'Audit Config Date Time',     key: 'audit_date'},
    { header: 'Audit Updated Date time',    key: 'qc_audit_date'},
    { header: 'Latitude',                   key: 'lat'},
    { header: 'Longtitude',                 key: 'lng'},
    { header: 'Total Points ',              key: 'total_points'},
    { header: 'Remarks',                    key: 'remarks'}
  ]

    finalColumns = columns2.concat(column3);

    let WorkSheetColumns = [];
    if(finalColumns.length > 0) {
      finalColumns.forEach(
        d=> {
          if(typeof d == "string") {
            d = JSON.parse(d);
          }
          WorkSheetColumns.push(d);
        }
      )
    };
    worksheet.columns = WorkSheetColumns;

    let u = 0;
    if(excelArray.length > 0) {
      excelArray.forEach(d => {
          let row = worksheet.addRow(d);
          row.getCell(1).value = u + 1;
          row.font = {
          name: 'Arial',
          size: 10,
          },
          row.alignment = {
          vertical: 'middle', horizontal: 'center'
          };
          u++;
          debugger;
        }
      );
    };

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    console.log(worksheet);

    debugger;
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      console.log(data);
      debugger;
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Qc Report -'+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    });

    excelArray = [];
    excelData = [];
    header1 = [];
    questionHeaders = [];
    header2 =[];
    columns1 = [];
    columns2 = [];
    column3 = [];
    finalColumns = [];
    j = 0 ;
    this.keyValueData = '';
  }

  /******************************************************** */

  QCTExcel(excelData, selectedRegionName, selectedMonthName, selectedYearName) {
    const header = this.qcReport;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('DAU Time Spent', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 10,
          color: { argb: 'FFFFFF'}
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );
    worksheet.columns = [
      { header: 'Sno',                key: 'sl_no' },
      { header: 'Outlet Code.',      key: 'outlet_code' },
      { header: 'Outlet Name',        key: 'outlet_name'},
      { header: 'Mystery Shopper Name',  key: 'mystery_name'},
      { header: 'Status',              key: 'qc_flag'},
    ]

//     assignment_id: 182
// audit_date: "2020-08-24 12:28:00"
// channel: "Counter A"
// internal_qc_flag: "action_button"
// internal_remarks: null
// lat: null
// lng: null
// mystery_name: "Kalyan"
// mystery_shopper_user_id: 63646
// outlet_code: "683871"
// outlet_mobile: "9741117407"
// outlet_name: "Sip Ventures"
// qc_audit_date: null
// qc_flag: "action_button"
let u = 0;
    if(excelData.length > 0) {
      debugger
      excelData.forEach(d => {
        if(d.qc_flag == "action_button"){
          d.qc_flag = "Pending";
        }
        let row = worksheet.addRow(d);
          row.getCell(1).value = u + 1;
          row.font = {
            name: 'Arial',
            size: 10,
          },
          row.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
          u++;
        }

      );
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'QC Audit Data -'+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }

}
