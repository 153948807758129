import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { type } from 'os';
import { RegionModel } from 'src/app/model/contract/channel';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { CommonProvider } from 'src/app/shared/Providers/Shared/CommonProvider';
import { DAILY_REPORT, MONTH, MONTH_DROPDOWN_DETAILS, WEEKLY_DROPDOWN_DETAILS } from '../../Models/general-headings.model';
import { CommonHttpServiceService } from '../../Services/common-http-service.service';
import { ExportExcelService } from '../../Services/export-excel.service';
import { GeneralService } from '../../Services/general.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import * as moment from 'moment';
@Component({
  selector: 'app-weekly-report',
  templateUrl: './weekly-report.component.html',
  styleUrls: ['./weekly-report.component.scss']
})
export class WeeklyReportComponent implements OnInit {

  public form: FormGroup;
  submittedCampaign: boolean;
  projectSettings: any[] = DAILY_REPORT;
  minDate: Date;
  maxDate: Date;
  startdate: string;
  enddate: string;
  reports: any[];
  weekOrMonth: any[];
  showWeek: boolean = false;
  showDate: boolean;
  flag: any;
  dateRange : any;
  dropdown: any[] = WEEKLY_DROPDOWN_DETAILS;
  months : any[] = MONTH;
  resetDropdown: boolean;
  regions: RegionModel[];
  min: number;
  max: number;
  years : any[] = [];
  selectedRegion : number;
  selectedMonth : number;
  selectedYear : string;
  selectedReport : number;
  data: any;
  myFilter = (d: Date): boolean => {
		const day = d.getDay();
		// Prevent Saturday and Sunday from being selected.
		return day !== 0 && day !== 6;
	}
  constructor(public routerDataProvider: RouteDataProvider,
    public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    public hubAdminDataProvider: HubAdminDataProvider,
    public generalService: GeneralService,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private httpClient: CommonHttpServiceService,
    private toastr: ToastrService,
    private excel : ExportExcelService) {
      this.minDate = new Date();
      // let day = this.maxDate.getDay();

      this.maxDate = new Date();
    }

    bsConfig: any;
    colorTheme = 'theme-green';
    applyTheme() {
      // create new object on each property change
      // so Angular can catch object reference change
      this.bsConfig = Object.assign({}, {
        containerClass: this.colorTheme,
        dayDisabled: [0, 1, 2],
        daysOfWeekDisabled: [0, 6]
      });
    }

  ngOnInit() {
    this.applyTheme();
    this.ngxLoader.start();
    setTimeout(() => {
      this.ngxLoader.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 2000);
    this.min = 2019,
    this.max = new Date().getFullYear();
    for (var i = this.min; i<=this.max; i++){
        this.years.push({id: i , name : i});
    }
    console.log(this.years);
  }

  errorMessage : any;
  date : any;

  searchByFilter() {
    this.errorMessage = (this.dateRange == undefined)? this.errorMessage = "Please select the date*": this.errorMessage = "";
    if(this.dateRange != undefined) {
      this.date = moment(this.dateRange).format("YYYY-MM-DD");
      if(typeof this.selectedRegion == "undefined") (this.selectedRegion = null),this.selectedRegionName ="All Region";
      if(typeof this.selectedReport == "undefined") this.dropdown.find(i => i.id == 2).errorStatus = true;
      // this.date = (typeof this.date == "undefined") ? (this.date = null): this.date;
      if(typeof this.selectedReport !="undefined" && this.date !="undefined") {
      if(this.selectedReport != null) {
        this.httpClient.getWeeklyReports(this.date, this.selectedRegion, this.selectedReport)
        .subscribe((res: any) => {
          if(res.success) {
            if(res.data.report_data.length > 0) {
              this.excel.exportLivePointsExcel(res.data.report_data, this.selectedRegionName, this.selectedReportName);
            }
            else{
              this.toastr.error(`No Data Found`,
                'Weekly Report', {
                timeOut: 10000,
                closeButton: true,
                positionClass: "toast-top-center",
              });
            }
          }
        },
          error => {
            // unauthorized access
            if (error.status == 401 || error.status == 403) {
            } else {
              // this.alertService.error(error.data.message);
              this.toastr.error(`Internal Server Error`,
                'Dashboard', {
                timeOut: 10000,
                closeButton: true,
                positionClass: "toast-bottom-right",
              });
            }
          }
        );
      }
    }
    }
    else{
      return;
    }
    //this.excel.exportRedmiptionExcel(0);
  }

  ngDoCheck() {
    this.regions = this.commonProvider.regionList;
    this.reports = this.commonProvider.reportLists;
    if(typeof this.reports !="undefined") {
      if(this.reports.length > 0) {
        this.reports = this.reports.filter(r => r.is_frequency == 3);
      }
    }
    this.dropdown.find(d => d.id == 1).data = this.regions;
    this.dropdown.find(d => d.id == 2).data = this.reports;
  }

  resetFilter() {
    this.resetDropdown = true;
  }

  selectedRegionName: string;
  selectedReportName : string;
  selectedValueCheck(event, filter): void {
    this.selectedRegion = 0;
    switch (filter) {
        case 1:
          if(event.length > 0) {
            this.selectedRegion = event.find(i => i.id).id;
            this.selectedRegionName = event.find(i => i.name).name;
          }
          else{
            this.selectedRegion = undefined;
          }
          break;
        case 2:
          if(event.length > 0) {
            this.selectedReport = event.find(i => i.id).id;
            this.dropdown.find(i => i.id == 2).errorStatus = false;
            this.selectedReportName = event.find(i => i.name).name;
          }
          else{
            this.selectedReport = undefined;
          }
          break;
    }
    console.log("general:", event, filter);
  }

}
