import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { UserDataService } from 'src/app/model/user-data.service';
import { ToastrService } from 'ngx-toastr';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { DatePipe } from '@angular/common';
import { RegionModel, ChartModel, ChannelModel } from 'src/app/model/contract/channel';
import { StateModel } from 'src/app/model/contract/menu-program';
import { RegionDataService } from 'src/app/model/region-data.service';

@Component({
  selector: 'app-kingservice',
  templateUrl: './kingservice.component.html',
  styleUrls: ['./kingservice.component.scss']
})
export class KingserviceComponent implements OnInit {
  oneAtATime: boolean = true;
  private _form: FormGroup;
  region: number;
  totalsmscount: any;
  totalemailcount: any;
  totalposmcounts: any;
  totalcoolercounts: any;
  totalengagementcount: any;
  outletsignuptrendsdaily: any;
  tsesignuptrendsdaily: any;
  waitersignuptrendsdaily: any;
  LandDData: any;
  LandDDataChartLabels: any;
  TopEngagementData: any;
  TopEngagementLabels: any;
  TopLDData: any;
  TopLDLabels: any;
  selectedChannel: number;
  state: number;
  selectedRole: number;
  tse_user_id: number;
  m2_user_id: number;
  m3_user_id: number;
  tseusers: any;
  m2users: any;
  m3users: any;
  pointstargetachievedpercentage: any;
  pointsdistrubtedusers: any;
  kingserviceopenrate: any;
  kingserviceavgopenrate: any;
  openrateperschemes: any;
  constructor(
    private hubAdminDataProvider: HubAdminDataProvider,
    public routerDataProvider: RouteDataProvider,
    public formBuilder: FormBuilder,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private users: UserDataService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    public settingDataService: SettingDataService,
    public datepipe: DatePipe,
    public usersService: RegionDataService
  ) {

    this._form = this._formBuilder.group({
      date: ['',],
      region: ['',],
      state: ['',],
      channel: ['',],
      role: ['',],
      tse_user_id: ['',],
      m2_user_id: ['',],
      m3_user_id: ['',]
    });


  }
  RoleList: any[];
  ngOnInit() {
    this.getRegions();
    this.getStateList();
    this.getChannels();
    this.getUsers();
    this.region = 0;
    this.getReport(0, 0, 0, 0, 0, 0, 0, 0)
    this.region = 0;
    this.selectedChannel = 0;
    this.state = 0;
    this.selectedRole = 0;
    this.tse_user_id = 0;
    this.m2_user_id = 0;
    this.m3_user_id = 0;
  }


  startDate: any = 0;
  endDate: any = 0;
  onSearch(date: any, region: any, state: any, channel: any, tse_user_id, m2_user_id, m3_user_id) {
    if (date != "") {
      this.startDate = this.datepipe.transform(date[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(date[1], 'yyyy-MM-dd');
    }
    this.getReport(this.startDate, this.endDate, region, state, channel, tse_user_id, m2_user_id, m3_user_id);
  }

  circleWiseData: Array<string> = [];
  public circleLabels: Array<string> = [];

  public TSEDailybarChartLabels: any[] = [

  ];

  public TSEDialybarChartData: any[] = [

  ];
  public TSEDailybarChartLabels1: any[] = [

  ];

  public TSEDialybarChartData1: any[] = [

  ];
  public TSEDialybarChartData2: any[] = [];
  public TSEDailybarChartLabels2: any[] = [];

  public bottomRegionChartData: any[] = [];
  public bottomRegionChartLabels: any[] = [];

  public CategoryChartData: any[] = [];
  public CategoryLabels: any[] = [];


  public TopOutletTargetVsAchivements: any[];
  public BottomOutletTargetVsAchivements: any[];
  public TopRegionTargetVsAchivements: any[];
  public BottomRegionTargetVsAchivements: any[];

  categoryData: any[];
  targetAchivement: any[];



  public TSEDialybarChartData3: any[] = [];
  public TSEDailybarChartLabels3: any[] = [];
  stateWiseTarget: any[];

  public TSEDialybarChartData4: any[] = [];
  public TSEDailybarChartLabels4: any[] = [];

  public TSEDialybarChartData5: any[] = [];
  public TSEDailybarChartLabels5: any[] = [];

  public TSEDialybarChartData6: any[] = [];
  public TSEDailybarChartLabels6: any[] = [];

  public TSEDialybarChartData7: any[] = [];
  public TSEDailybarChartLabels7: any[] = [];

  stateWisePoints: any[];


  setAllBlank() {
    this.CategoryChartData = [];
    this.CategoryLabels = [];
    this.TSEDialybarChartData3 = [];
    this.TSEDailybarChartLabels3 = [];
    this.TSEDialybarChartData4 = [];
    this.TSEDailybarChartLabels4 = [];
    this.TSEDialybarChartData5 = [];
    this.TSEDailybarChartLabels5 = [];
    this.TSEDialybarChartData6 = [];
    this.TSEDailybarChartLabels6 = [];
    this.TSEDialybarChartData7 = [];
    this.TSEDailybarChartLabels7= [];
  }

  getReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id) {
    this.setAllBlank();
    this.ngxLoader.start();
    this.users.getKingServiceReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id)
      .subscribe(
        (result: any) => {
          if (Object.getOwnPropertyNames(result.data.targetvsachivements).length !== 0) {
            this.targetAchivement = result.data.targetvsachivements;
            this.circleWiseData = Object.values(result.data.targetvsachivements[0]);
            this.circleLabels = ['Target Quantity', 'Sales Quantity'];
          }

          if (Object.getOwnPropertyNames(result.data.topoutlettargetvsachivements).length !== 0) {
            this.TopOutletTargetVsAchivements = result.data.topoutlettargetvsachivements;
          }

          if (Object.getOwnPropertyNames(result.data.leastoutlettargetvsachivements).length !== 0) {
            this.BottomOutletTargetVsAchivements = result.data.leastoutlettargetvsachivements;
          }

          if (Object.getOwnPropertyNames(result.data.topregiontargetvsachivements).length !== 0) {
            this.TopRegionTargetVsAchivements = result.data.topregiontargetvsachivements;

          }

          if (Object.getOwnPropertyNames(result.data.leastregiontargetvsachivements).length !== 0) {
            this.BottomRegionTargetVsAchivements = result.data.leastregiontargetvsachivements;
          }

          if (Object.getOwnPropertyNames(result.data.categorywisetargetachivements).length !== 0) {
            let data = result.data.categorywisetargetachivements;
            this.categoryData = data;
            this.CategoryLabels = Object.values(result.data.categorywisetargetachivements).map(e => e['brand_category_name']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.categorywisetargetachivements).map(e => e['target_qty']);
              chart.label = 'Target Quantity';
            });

            data.forEach(element => {
              chart1.data = Object.values(result.data.categorywisetargetachivements).map(e => e['sales_qty']);
              chart1.label = 'Sales Quantity';
            });

            this.CategoryChartData.push(chart);
            this.CategoryChartData.push(chart1);
          }


          if (Object.getOwnPropertyNames(result.data.statewisetargetvsachivements).length !== 0) {
            let data = result.data.statewisetargetvsachivements;
            this.stateWiseTarget = data;
            this.TSEDailybarChartLabels3 = Object.values(result.data.statewisetargetvsachivements).map(e => e['state_name']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.statewisetargetvsachivements).map(e => e['target_qty']);
              chart.label = 'Target Quantity';
            });

            data.forEach(element => {
              chart1.data = Object.values(result.data.statewisetargetvsachivements).map(e => e['sales_qty']);
              chart1.label = 'Sales Quantity';
            });

            this.TSEDialybarChartData3.push(chart);
            this.TSEDialybarChartData3.push(chart1);
          }


          if (Object.getOwnPropertyNames(result.data.statewisepoints).length !== 0) {
            let data = result.data.statewisepoints;
            this.stateWisePoints = data;
            this.TSEDailybarChartLabels4 = Object.values(result.data.statewisepoints).map(e => e['state_name']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.statewisepoints).map(e => e['engagement_points']);
              chart.label = 'Points';
            });
            this.TSEDialybarChartData4.push(chart);
          }

          if (Object.getOwnPropertyNames(result.data.pointstargetachievedpercentage).length !== 0) {
            let data = result.data.pointstargetachievedpercentage;
            this.pointstargetachievedpercentage = data;
            this.TSEDailybarChartLabels5 = Object.values(result.data.pointstargetachievedpercentage).map(e => e['range_percentage']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.pointstargetachievedpercentage).map(e => e['total_points']);
              chart.label = 'Points';
            });
            this.TSEDialybarChartData5.push(chart);
          }

          if (Object.getOwnPropertyNames(result.data.pointsdistrubtedusers).length !== 0) {
            let data = result.data.pointsdistrubtedusers;
            this.pointsdistrubtedusers = data;
            this.TSEDailybarChartLabels6 = Object.values(result.data.pointsdistrubtedusers).map(e => e['range_percentage']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.pointsdistrubtedusers).map(e => e['total_users']);
              chart.label = 'Total User';
            });
            this.TSEDialybarChartData6.push(chart);
          }

          if (Object.getOwnPropertyNames(result.data.openrateperschemes).length !== 0) {
            let data = result.data.openrateperschemes;
            this.openrateperschemes = data;
            this.TSEDailybarChartLabels7 = Object.values(result.data.openrateperschemes).map(e => e['scheme_title']);
            let chart: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.openrateperschemes).map(e => e['open_rate']);
              chart.label = 'Open Rate';
            });
            this.TSEDialybarChartData7.push(chart);
          }

          this.kingserviceopenrate = result.data.kingserviceopenrate;
          this.kingserviceavgopenrate = result.data.kingserviceavgopenrate;

          this.ngxLoader.stop();
        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            this.alertService.error(error.data.message);
          }
        }
      )
  }

  regionList: RegionModel[];

  public pieChartColors = [
    {
      backgroundColor: ['#d45087', '#552D39'],
    },
  ];

  public barChartColors: any[] = [
    {
      backgroundColor: '#ff7c43',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#ffa600',
      pointBorderColor: '#fff'
    },
  ]

  public barChartColors2: any[] = [
    {
      backgroundColor: '#487C94',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#33A698',
      pointBorderColor: '#fff'
    },
  ]

  public barChartColors3: any[] = [
    {
      backgroundColor: '#EE8767',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#F5975F',
      pointBorderColor: '#fff'
    },
  ]

  public barChartColors4: any[] = [
    {
      backgroundColor: '#e60000',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#ff6666',
      pointBorderColor: '#fff'
    },
  ]


  getRegions(): void {
    this.settingDataService.getAllRegion()
      .subscribe(
        (result: any) => {
          this.regionList = result.data.regions;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }


  onChangeRegion(item) {
    this.selectedStates = this.states.filter(s => s.region_id == item);
  }

  states: StateModel[];
  selectedStates: StateModel[];
  getStateList(): void {
    this.usersService.getAllStates().subscribe(
      (data) => {
        this.states = data;
      }
    )
  }

  onReset() {
    this.ngOnInit();
  }

  selectChannel() {

  }

  channelList: ChannelModel[];

  getChannels(): void {
    this.settingDataService.getAllChannel()
      .subscribe(
        (result: any) => {
          this.channelList = result.data.channels;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getUsers(): void {
    this.users.getUsers()
      .subscribe(
        (result: any) => {
          this.tseusers = result.data.tseusers;
          this.m2users = result.data.m2users;
          this.m3users = result.data.m3users;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }


}
