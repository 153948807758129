import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routing } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { BackendLayoutComponent } from './layout/backend-layout.component';
import { DataTableModule } from "angular2-datatable";
import { BreadcrumbsModule } from "ng6-breadcrumbs";
import { SimpleLayoutComponent } from './layout/simple-layout.component';
import { TooltipModule } from 'ngx-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DatePipe } from '@angular/common';
import { TreeviewModule } from 'ngx-treeview';
import { MysteryShopperProvider } from './Providers/MysteryShopperProvider';
import { AlertComponent } from '../shared/Components/alert/alert.component';
import { AlertService } from '../shared/Providers/RouterProvider/AlertProvider';
import { CampaignProvider } from '../shared/Providers/Shared/CampaignProvider';
import { UserRoleProvider } from '../shared/Providers/Shared/UserRoleProvider';
import { HierarchyProvider } from '../shared/Providers/Shared/HierarchyProvider';
import { ChartsModule } from 'ng2-charts';
import { BarChartComponent } from './dashboard/charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './dashboard/charts/line-chart/line-chart.component';
import { ProgramOverviewComponent } from './dashboard/program-overview/program-overview.component';
import { EngagementComponent } from './dashboard/engagement/engagement.component';
import { PieChartComponent } from './dashboard/charts/pie-chart/pie-chart.component';
import { TextChartComponent } from './dashboard/charts/text-chart/text-chart.component';
import { TextOneChartComponent } from './dashboard/charts/text-one-chart/text-one-chart.component';
import { GeneralReportsComponent } from './dashboard/general-reports/general-reports.component';
import { KingserviceComponent } from './dashboard/kingservice/kingservice.component';
import { VisiblityComponent } from './dashboard/visiblity/visiblity.component';
import { LeaderboardChartComponent } from './dashboard/charts/leaderboard-chart/leaderboard-chart.component';
import { RewardReportsComponent } from './dashboard/reward-reports/reward-reports.component';
import { CrownReportComponent } from './dashboard/crown-report/crown-report.component';
import { ExportExcelService } from '../report-dashboard/Services/export-excel.service';
// import {CommonDropdownComponent} from './SharedComponents/common-dropdown/common-dropdown.component'



@NgModule({
  imports: [
    CommonModule,
    TooltipModule.forRoot(),
    routing,
    SharedModule.forRoot(),
    DataTableModule,
    BreadcrumbsModule,
    Ng2SearchPipeModule,
    ChartsModule
  ],
  declarations: [
    BackendLayoutComponent,
    SimpleLayoutComponent,
    AlertComponent,
    ProgramOverviewComponent,
    EngagementComponent,
    GeneralReportsComponent,
    BarChartComponent,
    LineChartComponent,
    PieChartComponent,
    TextChartComponent,
    TextOneChartComponent,
    KingserviceComponent,
    VisiblityComponent,
    LeaderboardChartComponent,
    RewardReportsComponent,
    CrownReportComponent,


    //MysteryshopperComponent
  ],
  providers: [DatePipe,
    MysteryShopperProvider,
    CampaignProvider,
    AlertService,
    UserRoleProvider,
    HierarchyProvider,
    AlertService,
    UserRoleProvider,
    HierarchyProvider,
    ExportExcelService]
})
export class AdminModule { }
