import { Component, OnInit, Input } from '@angular/core';
import { StaffService } from 'src/app/model/staff.service';
import { UserDataService } from 'src/app/model/user-data.service';

@Component({
  selector: 'app-text-one-chart',
  templateUrl: './text-one-chart.component.html',
  styleUrls: ['./text-one-chart.component.scss']
})
export class TextOneChartComponent implements OnInit {
  @Input() userListModel: any;
  @Input() type: any;
  router: any;
  searchParams: any;
  totalCount: any;
  errorMessage: any;
  currentPage: number;
  pageSize: number;
 
  constructor(private staffService: StaffService, private users: UserDataService) { }

  ngOnInit() {
  
  }




}
