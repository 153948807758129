import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BackendLayoutComponent } from './layout/backend-layout.component';
import { SimpleLayoutComponent } from './layout/simple-layout.component';
import { AuthGuard } from '../model/auth.guard';
import { ProgramOverviewComponent } from './dashboard/program-overview/program-overview.component';
import { EngagementComponent } from './dashboard/engagement/engagement.component';
import { GeneralReportsComponent } from './dashboard/general-reports/general-reports.component';
import { KingserviceComponent } from './dashboard/kingservice/kingservice.component';
import { VisiblityComponent } from './dashboard/visiblity/visiblity.component';
import { RewardReportComponent } from './rewards/reward-report/reward-report.component';
import { RewardReportsComponent } from './dashboard/reward-reports/reward-reports.component';
import { CrownReportComponent } from './dashboard/crown-report/crown-report.component';
import { MysteryshopperComponent } from './dashboard/mysteryshopper/mysteryshopper.component';


export const routing = RouterModule.forChild([
  {
    path: 'admin',
    component: BackendLayoutComponent,
    data: {
      breadcrumb: 'Hub Admin'
    },
    canActivate: [AuthGuard],
    children: [
      { path: 'geo-hierarchy', loadChildren: () => import('./settings/Components/geographical-hierarchy/geographical-hierarchy.module').then(m => m.GeographicalHierarchyModule), data: { breadcrumb: 'Geographical Hierarchy' } },
      { path: 'profile-hierarchy', loadChildren: () => import('./settings/Components/user-profile/user-profile.module').then(m => m.UserProfileModule), data: { breadcrumb: 'User Profile' } },
      { path: 'add-user', loadChildren: () => import('./settings/Components/add-user/add-user.module').then(m => m.AddUserModule), data: { breadcrumb: 'User' } },
      { path: 'campaign', loadChildren: () => import('./settings/Components/campaign/campaign.module').then(m => m.CampaignModule), data: { breadcrumb: 'Campaign' } },
      { path: 'campaign-test', loadChildren: () => import('./settings/Components/campaign-test/campaign-test.module').then(m => m.CampaignTestModule), data: { breadcrumb: 'Campaign' } },
      { path: 'add-mystery-shopper', loadChildren: () => import('./mystery/Components/add-mystery-shopper/add-mystery-shopper.module').then(m => m.AddMysteryShopperModule), data: { breadcrumb: 'Mystery Shopper Configuration' } },
      { path: 'assign-mystery-shopper', loadChildren: () => import('./mystery/Components/assign-mystery-shopper/assign-mystery-shopper.module').then(m => m.AssignMysteryShopperModule), data: { breadcrumb: 'Mystery Shopper Configuration' } },
      { path: 'mystery-audit-qc', loadChildren: () => import('./mystery/Components/mystery-qc/mystery-qc.module').then(m => m.MysteryQcModule), data: { breadcrumb: 'Mystery-Qc' } },
      { path: 'qc-report', loadChildren: () => import('./mystery/Components/qc-report/qc-report.module').then(m => m.QcReportModule), data: { breadcrumb: 'Qc-Report' } },
      { path: 'mystery-audit-template', loadChildren: () => import('./mystery/Components/mystery-audit-template/mystery-audit-template.module').then(m => m.MysteryAuditTemplateModule), data: { breadcrumb: 'Mystery Shopper Configuration ' } },
      { path: 'mystery-outlet-whitelist', loadChildren: () => import('./mystery/Components/mystery-whitelist/mystery-whitelist.module').then(m => m.MysteryWhitelistModule), data: { breadcrumb: 'Mystery Shopper Outlet Whitelist' } },
      { path: 'loyalty-banner', loadChildren: () => import('./loyalty/Components/loyalty-banners/loyalty-banners.module').then(m => m.LoyaltyBannersModule), data: { breadcrumb: 'Loyalty Banner' } },
      { path: 'schemes-offers', loadChildren: () => import('./loyalty/Components/schemes-offers/schemes-offers.module').then(m => m.SchemesOffersModule), data: { breadcrumb: 'Schemes offers' } },
      { path: 'whitelist-outlets', loadChildren: () => import('./loyalty/Components/whitelist-outlets/whitelist-outlets.module').then(m => m.WhitelistOutletsModule), data: { breadcrumb: 'Targets and Achivements' } },
      { path: 'point-structure', loadChildren: () => import('./loyalty/Components/point-structure/point-structure.module').then(m => m.PointStructureModule), data: { breadcrumb: 'Points Structure' } },
      { path: 'outlet-hierarchy', loadChildren: () => import('./settings/Components/outlet-hierarchy/outlet-hierarchy.module').then(m => m.OutletHierarchyModule), data: { breadcrumb: 'Outlet Hierarchy' } },
      { path: 'posm', loadChildren: () => import('./visibity/Components/posm/posm.module').then(m => m.PosmModule), data: { breadcrumb: 'Visibility' } },
      { path: 'content-learning', loadChildren: () => import('./loyalty/Components/content-learning/content-learning.module').then(m => m.ContentLearningModule), data: { breadcrumb: 'Content & Learning' } },
      { path: 'communication', loadChildren: () => import('./communication/Components/communication/communication.module').then(m => m.CommunicationModule), data: { breadcrumb: 'Communication' } },
      { path: 'trigger', loadChildren: () => import('./communication/Components/communication-trigger/communication-trigger.module').then(m => m.CommunicationTriggerModule), data: { breadcrumb: 'Communication Trigger' } },
      { path: 'leaderboard', loadChildren: () => import('./settings/Components/leaderboard/leaderboard.module').then(m => m.LeaderboardModule), data: { breadcrumb: 'Leaderboard' } },
      { path: 'crown', loadChildren: () => import('./tertiary-programs/crown-collections/crown-collections.module').then(m => m.CrownCollectionsModule), data: { breadcrumb: 'Crown Collection' } },
      { path: 'carton-insert', loadChildren: () => import('./tertiary-programs/carton-insert/carton-insert.module').then(m => m.CartonInsertModule), data: { breadcrumb: 'Carton Insert' } },
      { path: 'menu-program', loadChildren: () => import('./menu-programs/menu-program/menu-program.module').then(m => m.MenuProgramModule), data: { breadcrumb: 'Menu Programs' } },
      { path: 'menu-program-overview', loadChildren: () => import('./menu-programs/menu-program-overview/menu-program-overview.module').then(m => m.MenuProgramOverviewModule), data: { breadcrumb: 'Menu Programs Overview' } },
      { path: 'menu-brand-template', loadChildren: () => import('./menu-programs/menu-brand-template/menu-brand-template.module').then(m => m.MenuBrandTemplateModule), data: { breadcrumb: 'Add Menu Brand and Template' } },
      { path: 'menu-program-config', loadChildren: () => import('./menu-programs/menu-program-config/menu-program-config.module').then(m => m.MenuProgramConfigModule), data: { breadcrumb: 'Menu Brand Configuration' } },
      { path: 'reward', loadChildren: () => import('./rewards/reward-report/reward-report.module').then(m => m.RewardReportModule), data: { breadcrumb: 'Reward Report' } },
      { path: 'program-overview-dashboard', component: ProgramOverviewComponent, data: { breadcrumb: 'General Reports' } },
      { path: 'engagement-dashboard', component: EngagementComponent, data: { breadcrumb: 'Engagement Reports' } },
      { path: 'l&D-dashboard', component: GeneralReportsComponent, data: { breadcrumb: 'L & D Reports' } },
      { path: 'kingservice-dashboard', component: KingserviceComponent, data: { breadcrumb: 'KingService Reports' } },
      { path: 'visiblity-dashboard', component: VisiblityComponent, data: { breadcrumb: 'Visiblity Reports' } },
      { path: 'reward-dashboard', component: RewardReportsComponent, data: { breadcrumb: 'Reward Reports' } },
      { path: 'crown-collection-dashboard', component: CrownReportComponent, data: { breadcrumb: 'Crown Reports' } },
      { path: 'mystery-shopper-dashboard', loadChildren: () => import('./dashboard/mysteryshopper/mysteryshopper.module').then(m => m.MysteryshopperModule), data: { breadcrumb: 'Mysteryshopper Reports' } },
    ]
  }
])


