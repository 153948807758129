import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseBody } from './contract/response-body';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { RegionModel } from './contract/region';
import { MenuBrandModel, MenuTemplate, MenuTemplateSizeModel, MenuBrandTemplateModel, MenuBrandChannelModel } from './contract/menu-program';



const user = '/hub-admin/getassignedusers';
const state = '/hub-admin/getallstates';
const template = '/hub-admin/gettemplates';
const brand = '/hub-admin/getallmenubrand';
const menutemplate = '/hub-admin/getmenutemplates';
const addBrand = '/hub-admin/addbrand';
const addTemplate = '/hub-admin/addmenutemplate';
const addChannel = '/hub-admin/addmenuchannel';
const request = '/hub-admin/getallmenurequest';
const requestTrans = '/hub-admin/getmenutransactionsbyid';
const region = '/hub-admin/getallregion';
const menutemplateconfig = '/hub-admin/getmenutemplateconfig';
const addbrandtemplate = '/hub-admin/addbrandtemplate';
const menuBrandChannel = '/hub-admin/getbrandchannels';




@Injectable()
export class RegionDataService {

  constructor(private globalService: GlobalService,
    private staffService: StaffService,
    private http: HttpClient) { }

  private getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.staffService.getToken()
    })
  }

  private getURLList() {
    return this.globalService.apiHost + '/admin';
  }

  private getURLList1() {
    return this.globalService.apiHost;
  }

  getRegionList(): Observable<RegionModel> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/region';
    return this.http.get<RegionModel>(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <RegionModel>response;
        }
      )
  }

  addRegionData(region: RegionModel): Observable<any> {
    let headers = this.getHeaders();
    let body = JSON.stringify(region);
    let apiUrl = this.getURLList() + '/addregion';
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
  }

  updateRegionData(id: number, region: RegionModel): Observable<ResponseBody> {
    let headers = this.getHeaders();
    let body = JSON.stringify(region);
    let apiUrl = this.getURLList() + '/updateregion?id=' + id;
    return this.http.put<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
  }

  //POST /v1/admin
  getSearchResult(searchValue: any): Observable<RegionModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/searchregion';
    let body = JSON.stringify({ 'search_text': searchValue });
    return this.http.post(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <RegionModel[]>response;
        }
      )
  }

  //GET /v1/admin

  getRegionById(id: number): Observable<RegionModel> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/regionbyid?id=' + id;

    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <RegionModel>response;
        }
      )
  }


  getAllStates(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + state;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getAllLanguages(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/hub-admin/getlanguages';
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getAllTemplate(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + template;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }



  getAllBrands(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + brand;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getAllMenuBrandChannel(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + menuBrandChannel;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }


  addBrandMenuTemplate(data: MenuBrandTemplateModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + addbrandtemplate;
    let body = data;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  addMenuBrandChannel(data: MenuBrandChannelModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + addChannel;
    let body = data;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }


  getAllMenuTemplates(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + menutemplate;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getAllMenuTemplatesConfig(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + menutemplateconfig;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  addMenuBrand(data: MenuBrandModel): Observable<any> {
    debugger
    let headers = this.getHeaders();
    let body = JSON.stringify(data);
    let apiUrl = this.getURLList1() + addBrand;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response.data;
        }
      )
  }

  addMenuTemplate(data: MenuTemplateSizeModel): Observable<any> {
    let headers = this.getHeaders();
    let body = JSON.stringify(data);
    let apiUrl = this.getURLList1() + addTemplate;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response.data;
        }
      )
  }

  addMenuChannel(data: MenuBrandChannelModel): Observable<any> {
    let headers = this.getHeaders();
    let body = JSON.stringify(data);
    let apiUrl = this.getURLList1() + addTemplate;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response.data;
        }
      )
  }


  getAllTemplateMenuRequest(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + request;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getAllTemplateMenuRequestTransaction(id: number): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + requestTrans + "?menu_id=" + id;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getAllRegions(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + region;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data.regions;
          }
        ),
        catchError(this.handleError)
      )
  }

  private handleError(response: any) {

    let errorMessage: any = {};
    // Connection error
    if (response.error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }

    return throwError(errorMessage);
  }
}
